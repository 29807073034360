export const getNameById = (id, users) => {
    const searhedUser = users.p_users.find((user) => user.id === id)
    if (searhedUser)
        return searhedUser.name;
    const searhedUserSuper = users.p_supervisors.find((user) => user.id === id)
    if (searhedUserSuper)
        return searhedUserSuper.name;
    const searhedUserAdmin = users.p_admin.find((user) => user.id === id)
    if (searhedUserAdmin)
        return searhedUserAdmin.name;
    const searhedUserCoordinator = users.p_coordinator.find((user) => user.id === id)
    if (searhedUserCoordinator)
        return searhedUserCoordinator.name;
    const searhedUserBrigadier = users.p_brigadier.find((user) => user.id === id)
    if (searhedUserBrigadier)
        return searhedUserBrigadier.name;

    return "Пользователь"

}
const defaultState = [];

function projectsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'ADD_PROJECT':
            console.log("ADD_PROJECT");
            return [
                action.project,
                ...state

            ];
        case 'DELETE_PROJECT':
            console.log("DELETE_PROJECT");
            return state.filter(item => item.id !== action.id)
        case 'SET_PROJECTS':
            console.log("SET_PROJECTS");
            return [
                ...action.projects
            ];
        case 'SET_PROJECT_USERS':
            console.log("SET_PROJECT_USERS");
            const indexPrj = state.findIndex(project => project.id === action.project_id);
            return [...state.map((item, index) => {
                if (index !== indexPrj) {
                    return item;
                } else {
                    return {
                        ...item,
                        project_users: action.project_users
                    }
                }
            })]
        case 'SET_PROJECT_SUPERVISORS':
            console.log("SET_PROJECT_SUPERVISORS");

            const indexPrjS = state.findIndex(project => project.id === action.project_id);

            return [...state.map((item, index) => {
                if (index !== indexPrjS) {
                    return item;
                } else {
                    return {
                        ...item,
                        project_supervisors: action.project_users
                    }
                }
            })]
        case 'SET_PROJECT_COORDINATORS':
            console.log("SET_PROJECT_COORDINATORS");

            const indexPrjC = state.findIndex(project => project.id === action.project_id);

            return [...state.map((item, index) => {
                if (index !== indexPrjC) {
                    return item;
                } else {
                    return {
                        ...item,
                        project_coordinators: action.project_users
                    }
                }
            })]
        case 'SET_PROJECT_BRIADIERS':
            console.log("SET_PROJECT_BRIADIERS");

            const indexPrjB = state.findIndex(project => project.id === action.project_id);

            return [...state.map((item, index) => {
                if (index !== indexPrjB) {
                    return item;
                } else {
                    return {
                        ...item,
                        project_brigadiers: action.project_users
                    }
                }
            })]
        case 'UPDATE_PROJECT':
            const indexPrj_ = state.findIndex(project => project.id === action.project.id);
            return [...state.map((item, index) => {
                if (index !== indexPrj_) {
                    return item;
                } else {
                    return {

                        ...action.project
                    }
                }
            })]
        case 'DELETE_PROJECTS':
            console.log("DELETE_PROJECTS");
            return state.filter((el) => el.id !== action.id);
        case 'CLEAR_PROJECTS':
            console.log("CLEAR_PROJECTS");
            return [
                ...defaultState,
            ];
        default:
            return state;
    }
}

export default projectsReducer;


import { requestApi } from ".";
export default class TaskService {
    static async getProjectTasks(id) {
        try {
            const response = await requestApi("api/project_task/search",
                {
                    body: JSON.stringify({
                        "filters": [
                            {
                                "field": "id",
                                "comparison": "=",
                                "value": id
                            }
                        ]
                    }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async getProjectTasksSearch(id, filters, limit, page) {
        try {
            const response = await requestApi("api/project_task/search?limit=" + limit + "&page=" + page,
                {
                    body: JSON.stringify({
                        "filters": [
                            {
                                "field": "project_id",
                                "comparison": "=",
                                "value": id
                            },
                            ...filters
                        ]
                    }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async updateTask(id, task) {
        try {
            const response = await requestApi("api/project_task/" + id,
                {
                    body: JSON.stringify({ ...task }),
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async deleteTask(id) {
        try {
            const response = await requestApi("api/project_task/" + id,
                {

                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async createTask(task) {
        try {
            const response = await requestApi("api/project_task",
                {
                    body: JSON.stringify({ ...task }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async getTask(id) {
        try {
            const response = await requestApi("api/project_task/" + id,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }

}
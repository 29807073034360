import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Upload, Select, Typography, Button, DatePicker, InputNumber, TimePicker, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import TaskService from '../../services/TaskService';
import { addTask, addTaskFile, setTasks, updateTasks } from '../../actions/TasksActions';
import FileService from '../../services/FileService';
import { MaskedInput } from "antd-mask-input";
import { getNameById } from '../../utils/utils';
import moment from 'moment';
import "./ProjectPage.scss"
import { TwitterPicker } from 'react-color'
import styled from 'styled-components';
const { TextArea } = Input;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
const StyledUpload = styled(Upload)`

    .ant-upload-list{
        flex-wrap: wrap;
    }
    .ant-upload-list-item-card-actions{
        opacity:1
    }
    .ant-upload-list-item-actions{
        opacity:1
    }
    .ant-upload-list-item-card-actions-btn{
        opacity:1
    }
  `;

const UpdateTaskModal = ({ task, updateModalShow, setUpdateModalShow, create }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [isMobile, setIsMobile] = useState(window.screen.width < 450)
    const [targetProject, setTargetProject] = useState({ project_users: [] })
    const [projectUsers, setProjectUsers_] = useState([]);
    const [taskUsers, setTaskUsers] = useState([]);
    const [tempFiles, setTmpFiles] = useState([]);
    const [tempPhotos, setTmpPhotos] = useState([]);
    const projects = useSelector(state => state.projectsReducer);
    const tasks = useSelector(state => state.tasksReducer);
    const statuses = useSelector(state => state.statusReducer);
    const users = useSelector(state => state.usersReducer);
    const user = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [photoList, setPhotoList] = useState([]);
    const [form] = Form.useForm();
    const [date, setDate] = useState("");
    const [error, setError] = useState();
    const [phoneError, setPhoneError] = useState(true);
    const [targetColor, setTargetColor] = useState("#ffffff")
    const setFiles = () => {
        const newFilesFormat = task?.files?.filter(file => file.folder === "file").map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setFileList(newFilesFormat);
    }
    const setFilesTmp = (arr) => {
        const newFilesFormat = arr.map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setFileList(newFilesFormat);
    }

    const setPhotos = () => {
        const newFilesFormat = task?.files?.filter(file => file.folder === "photo").map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setPhotoList(newFilesFormat);
    }
    const setPhotoTmp = (arr) => {
        const newFilesFormat = arr.map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setPhotoList(newFilesFormat);
    }

    useEffect(() => {
        setError(false);
        setPhoneError(false);
        if (updateModalShow) {
            setTmpFiles([])
            setFiles();
            setTmpPhotos([])
            setPhotos();
            console.log(task.phone);
            if (task?.phone[0] === "8") {
                console.log(task.phone);
                form.setFieldsValue({ ...task, phone: "+7 (" + task.phone.substring(1, 4) + ") " + task.phone.substring(4, 7) + "-" + task.phone.substring(7, 9) + "-" + task.phone.substring(9, 11) })

            } else {
                form.setFieldsValue(task)
            }
            setTargetColor(task.backlight)
            if (task.plan_date) {
                const date_ = moment(moment(task.plan_date).format("DD.MM.YYYY HH:mm"), "DD-MM-YYYY HH:mm")
                setDate(date_);
            } else {
                setDate("")
            }

            const targetProject = projects.find(project => project.id === task.project_id);
            if (targetProject) {

                const selectedUsers = [...targetProject?.project_users.map((user) => {

                    return { value: user.user, label: getNameById(user.user, users) }
                })]
                const targetUsers_ = [...task?.project_task_users.map((user) => {

                    return { value: user.user, label: getNameById(user.user, users) }
                })]

                if (create) {
                    setTaskUsers([]);
                    form.setFieldsValue({ project_task_users: [] })
                }

                else {
                    setTaskUsers(targetUsers_);
                    form.setFieldsValue({ project_task_users: targetUsers_ })
                }

                setProjectUsers_(selectedUsers);


                setTargetProject(targetProject);
            }

        } else {
            setTaskUsers([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateModalShow]);

    const handleOk = async () => {
        setError(false);
        form
            .validateFields()
            .then((values) => {
                console.log(values);
                if (values.status_id === 5 && (values.rejection_comment === "" || values.rejection_comment == null)) {
                    setError(true);
                    return;
                }
                if (values.name === "" || values.name === null) {
                    values.name = "Неизвестно"
                }
                if (values.phone === "+7") {
                    setPhoneError(true);
                    return;
                }
                if (values.acceptance_act === 0) {
                    values.acceptance_act = null;
                }
                if (values.consent_act === 0) {
                    values.consent_act = null;
                }
                if (targetColor === "#ffffff") {
                    values.backlight = null;
                } else
                    values.backlight = targetColor;
                const usersIds = values.project_task_users.map(item => item.value === undefined ? item : item.value);
                values.project_task_users = usersIds;
                if (date === "" || date === null) {
                    values.plan_date = null;
                } else {
                    values.plan_date = moment(date).format("YYYY-MM-DD HH:mm");
                    if (values.status_id === 1) values.status_id = 2;
                }
                if (values.status_id === 2 && values.project_task_users.length > 0) {
                    values.status_id = 3;
                }
                if (values.status_id === 3 && values.project_task_users.length === 0) {
                    values.status_id = 2;
                }
                if (create) {

                    TaskService.createTask({ ...values, project_id: task.project_id }).then(response => {
                        setUpdateModalShow(false);
                        const newTask = response.data;
                        dispatch(addTask(newTask));
                        tempFiles.map(file => {
                            file.type_id = newTask.id;
                            FileService.updateFile(file.id, file).then(() => {
                                newTask.files.push(file)
                                dispatch(updateTasks(newTask));
                            }).catch(() => {
                                message.error("Не удалось загрузить файл")
                            })
                        })


                    }).catch(error => console.log("Error update task", error));
                } else {
                    TaskService.updateTask(task.id, values).then(response => {
                        setUpdateModalShow(false);
                        const newTask = response.data;
                        dispatch(updateTasks(newTask));


                    }).catch(error => console.log("Error update task", error));
                }

                // 

            }).catch(error => console.log("Error form", error));

    };
    const onDateChange = (date_, dateString) => {


        if (date !== "") {
            date.set({
                year: date_.get('year'),
                month: date_.month(),
                date: date_.date()
            })
            setDate(date)
        } else {
            date_.set({
                hour: 18,
                minute: 0
            })
            setDate(date_)
        }
    };
    const onTimeChange = (time, timeString) => {
        if (date !== "") {
            console.log(time, moment(date).startOf(time));
            date.set({
                hour: time.get('hour'),
                minute: time.get('minute')
            })
            setDate(date)
        } else {
            setDate(time)
        }

        //editGeneralData<string>("expire", dateString);
    };
    const handleCancel = () => {
        setUpdateModalShow(false);
    };



    const onUpdate = (values) => {
        console.log(values);
    }
    const beforeUpload = (file, folder) => {

        if (folder === "file") {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("Вы можете загрузить файл только в форматах JPG/PNG!");
            }
            setFileList([...fileList, {
                uid: "123123123",
                name: "",
                url: "",
                status: 'uploading',
                folder: folder
            }])
            return isJpgOrPng && 1;
        }
        else {
            setPhotoList([...fileList, {
                uid: "123123123",
                name: "",
                url: "",
                status: 'uploading',
                folder: folder
            }])
        }
        //const isLt2M = file.size / 1024 / 1024;
        // if (!isLt2M) {
        //     message.error("Изображенте должно быть менее 2MB!");
        // }
        return 1;
    };

    const DynamicPhone = (props) => {
        const cellphoneMask = "(00) 0 0000-0000";
        const phoneMask = "+7 (000) 000-00-00";

        // always memoize dynamic masks
        const mask = React.useMemo(
            () => [
                {
                    mask: cellphoneMask,
                    lazy: false,
                },
                {
                    mask: phoneMask,
                    lazy: false,
                },
            ],
            []
        );
        return (
            <MaskedInput
                {...props}
                mask={mask}
                maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                        const isCellPhone = dynamicMasked.unmaskedValue[2] === "9";
                        return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    },
                }}
            />
        );
    };
    function onImageChange(fileq, folder) {
        if (fileq.file.status === 'removed') {

            FileService.deleteFile(fileq.file.uid).then((response) => {
                if (response.status === 204) {
                    const del_index = tasks.findIndex(task_ => task_.id === task.id);
                    const newState = JSON.parse(JSON.stringify(tasks));
                    newState[del_index].files = newState[del_index].files.filter(file => file.id !== fileq.file.uid);
                    task.files = newState[del_index].files;
                    dispatch(setTasks(newState))

                    setFiles();
                    setPhotos();


                }
                console.log(response);
            }).catch(error => console.log("DELETE FILE", error));
        } else {
            const file = fileq.file.originFileObj;

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function () {
                // console.log(reader.result);
                FileService.uploadFile(task.id, reader.result, folder).then(response => {
                    console.log("RESPONSE ", response);


                    if (!create) {
                        dispatch(addTaskFile(task.id, response.data))
                        setFiles();
                        setPhotos();
                    }
                    else {

                        if (response.data.folder === "file") {
                            setTmpFiles([...tempFiles, response.data])
                            setFilesTmp([...tempFiles, response.data])
                        } else {
                            console.log("SET TMP FILES");
                            setTmpPhotos([...tempPhotos, response.data])
                            setPhotoTmp([...tempPhotos, response.data])
                        }

                    }
                }).catch(error => {
                    setFileList([...fileList.filter(file => file.status !== "uploading")])
                    message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
                });
            };

            reader.onerror = function () {
                console.log(reader.error);
                setFileList([...fileList.filter(file => file.status !== "uploading")])

                message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
            };
        }
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    return (
        <Modal

            visible={updateModalShow}
            forceRender
            onOk={handleOk}
            onCancel={handleCancel}
            style={{ top: 20 }}
            okText={"Сохранить"}

            cancelText={"Отмена"}
            footer={[

                <Button key="back" onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Сохранить
                </Button>,

            ]}
        >
            <Form layout="vertical"
                labelWrap
                wrapperCol={{ flex: 1 }}
                onFinish={onUpdate}
                form={form}
                initialValues={task}>
                <Input.Group compact={!isMobile}>
                    <Form.Item label="Имя" style={!isMobile ? { marginRight: 20, width: "55%" } : null} rules={[{ required: false, message: "Это поле нужно заполнить!" }]} name="name">
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <Input placeholder="Имя" /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{task.name}</Typography>}
                    </Form.Item>
                    <Form.Item
                        label="Телефон"
                        required
                        name="phone"
                        validateStatus={phoneError ? "error" : undefined}
                        help={phoneError ? "Заполните номер телефона!" : undefined}
                        rules={[
                            {
                                required: true,
                                message: "Некорректный телефон",
                                type: "string",
                            },
                        ]}
                    >
                        {/* <Input placeholder="Введите телефон" className="settings__input" /> */}
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <DynamicPhone /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{task.phone}</Typography>}

                    </Form.Item>
                </Input.Group>
                <Input.Group compact={!isMobile} >
                    <Form.Item label="Адрес" style={!isMobile ? { marginRight: 20, width: "55%" } : null} name="address" rules={[{ required: true, message: "Это поле нужно заполнить!" }]}>
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <Input placeholder="Адрес" /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{task.address}</Typography>}
                    </Form.Item>
                    <Form.Item label="Квартира" name="flat" rules={[{ required: true, message: "Это поле нужно заполнить!" }]}>
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <Input placeholder="Квартира" /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px", minHeight: 32 }}>{task.flat}</Typography>}
                    </Form.Item>
                </Input.Group>
                <Input.Group compact={!isMobile} >
                    <Form.Item label="Количество устройств" style={!isMobile ? { marginRight: 20 } : null} name="device_qty" rules={[{ required: true, message: "Это поле нужно заполнить!" }]}>
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <InputNumber min={1} max={1000} /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px", minHeight: 32 }}>{task.device_qty}</Typography>}
                    </Form.Item>
                    <Form.Item label="Серийные номера" style={!isMobile ? { width: "62%" } : null} name="device_serial_numbers" rules={[{ required: false, message: "Это поле нужно заполнить!" }]}>
                        {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <Input /> :
                            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px", minHeight: 32 }}>{task.device_serial_numbers}</Typography>}
                    </Form.Item>
                </Input.Group>

                <Input.Group compact={!isMobile}>
                    <Form.Item label="Акт принятия" name="acceptance_act"
                        style={{ width: !isMobile ? "40%" : null, marginRight: !isMobile ? 20 : null, display: (user.isAdmin || user.isCoordinator || user.isSupervisor) ? undefined : "none" }} rules={[{ required: task.status_id === 2 && user.isBrigadier, message: "Это поле нужно заполнить!" }]}>
                        <Select
                            allowClear
                            style={{
                                width: '100%',
                            }}

                            placeholder=""
                            // value={[...taskUsers]}
                            options={[{ value: 0, label: "Не выбрано" },
                            { value: 1, label: "Есть" },
                            { value: 2, label: "Нет" }]
                            }
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item label="Акт согласия" name="consent_act"
                        style={{ width: !isMobile ? "40%" : null, display: (user.isAdmin || user.isCoordinator || user.isSupervisor) ? undefined : "none" }} rules={[{ required: task.status_id === 2 && user.isBrigadier, message: "Это поле нужно заполнить!" }]}>
                        <Select
                            allowClear
                            style={{
                                width: '100%',
                            }}

                            placeholder=""
                            // value={[...taskUsers]}
                            options={[{ value: 0, label: "Не выбрано" },
                            { value: 1, label: "Есть" },
                            { value: 2, label: "Нету" }]
                            }
                        >
                        </Select>
                    </Form.Item>
                </Input.Group>
                <Form.Item label="Доп. информация" name="comment" >
                    {(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator) ? <TextArea rows={3} placeholder="Доп. информация" /> :
                        <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px", minHeight: 32 }}>{task.comment}</Typography>}
                </Form.Item>

                <Form.Item label="Планируемая дата"
                    name="plan_date"
                    style={{
                        display: (
                            (user.isAdmin || user.isCoordinator))

                            ? undefined : "none"
                    }} >
                    <Input.Group compact >
                        <div onClick={e => e.stopPropagation()}>

                            <DatePicker
                                allowClear={task.status_id !== 1 || task.status_id !== 2}
                                value={date}
                                disabled={!(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator)}
                                style={{ marginRight: 20 }}
                                format="DD-MM-YYYY" placeholder="Дата"
                                onChange={onDateChange}
                            />
                            <TimePicker
                                allowClear={task.status_id !== 1 || task.status_id !== 2}
                                disabled={!(user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isCoordinator)}
                                value={date}
                                type={"time"}
                                format={"HH:mm"}
                                onChange={onTimeChange}
                                placeholder="Время" />

                        </div>
                    </Input.Group>
                </Form.Item>
                <Input.Group compact={!isMobile} >

                    <Form.Item label="Фотоотчет" style={{ marginRight: isMobile ? null : 20, display: ((user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isBrigadier || user.isUser)) ? undefined : "none" }} valuePropName="files">
                        <StyledUpload

                            multiple={true}
                            // action="/upload.do" 
                            fileList={photoList}
                            onPreview={handlePreview}
                            onChange={(fileq) => onImageChange(fileq, "photo")}
                            beforeUpload={(fileq) => beforeUpload(fileq, "photo")}
                            listType="picture-card">

                            {(photoList.length >= 10) ? null : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Загрузить</div>
                            </div>}

                        </StyledUpload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                            <img
                                alt="загруженное изображение"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </Form.Item>
                    <Form.Item label="Документы" style={{ display: (user.isAdmin || (user.isSupervisor && task.status_id < 2) || user.isBrigadier || user.isUser) ? undefined : "none" }} valuePropName="files">
                        <StyledUpload

                            multiple={true}
                            // action="/upload.do" 
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={(fileq) => onImageChange(fileq, "file")}
                            beforeUpload={(fileq) => beforeUpload(fileq, "file")}
                            listType="picture-card">

                            {(fileList.length >= 10) ? null : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Загрузить</div>
                            </div>}

                        </StyledUpload>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                            <img
                                alt="загруженное изображение"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>
                    </Form.Item>

                </Input.Group>
                <Form.Item label="Комментарий исполнителя" style={{ display: (user.isAdmin || user.isUser | user.isBrigadier) ? undefined : "none" }} validateStatus={error ? "error" : undefined} help={error ? "Заполните комментарий исполнителя!" : undefined} name="rejection_comment">
                    <TextArea rows={3} placeholder="Комментарий исполнителя" />
                </Form.Item>

                <Form.Item style={{ display: (user.isAdmin) ? undefined : "none" }} label="Статус" name="status_id">
                    <Select >
                        {
                            statuses.map(status => (
                                <Select.Option key={status.id} value={status.id}>{status.title}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item style={{ display: (user.isAdmin) ? undefined : "none" }} label="Цвет" name="backlight" >
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>

                        <div style={{
                            border: "1px solid #aeaeaeaa",
                            width: 28, height: 28,
                            background: targetColor,
                            borderRadius: 4,
                            boxShadow: "1px 0px 4px rgba(0, 0, 0, 0.3)"

                        }}>

                        </div>
                        <TwitterPicker className='color_picker' styles={{
                            default: {
                                hash: { display: "none" },
                                input: { display: "none" },
                                card: { boxShadow: "none" },
                                body: { boxShadow: "none" },
                                swatch: {
                                    border: "1px solid #aeaeaeaa",
                                },


                            }
                        }} triangle="hide" onChange={(color, event) => {
                            if (color?.hex) setTargetColor(color?.hex)
                        }} colors={["#ffffff", "#ffb3b3", "#ffdccc", "#fff5cc", "#cce6ff", "#d9f2d9"]} />
                    </div>
                </Form.Item>
                <Form.Item label="Исполнители" name="project_task_users"
                    style={{ display: (user.isAdmin || user.isBrigadier) ? undefined : "none" }} rules={[{ required: task.status_id === 2 && user.isBrigadier, message: "Это поле нужно заполнить!" }]}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}

                        placeholder="Выберите исполнителя"
                        value={[...taskUsers]}
                        options={targetProject.project_users !== undefined ?
                            projectUsers
                            :
                            []
                        }
                    >
                    </Select>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default UpdateTaskModal;
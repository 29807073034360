import React, { useState } from "react";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { Layout } from 'antd';

import "normalize.css";
import Sidebar from "../Sidebar/Sidebar.js";
import Header from "../Header/Header.js";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "../../pages/LoginPage/LoginPage.js";

import ProjectRoutes from "./ProjectRoutes.js";



const AppRouter = () => {
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(window.screen.width > 450 ? false : true);
  const location = useLocation();

  return (
    <Switch>
      <>
        {
          user.isLogin ?
            <Layout style={{ height: "100vh" }}>
              <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
              <Layout className="site-layout">
                <Header state={collapsed} setState={setCollapsed} />
                <Layout.Content
                  style={{
                    padding: "16px",
                    paddingLeft: window.screen.width > 450 ? "16px" : 0,
                    paddingRight: window.screen.width > 450 ? "16px" : 0,
                    overflowY: "auto",
                  }}
                >

                  <ProjectRoutes />

                </Layout.Content>
              </Layout>
            </Layout>
            :
            <>
              <Route path={"/login"} component={LoginPage} exact />
              <Redirect from='*' to='/login' />
            </>}
      </>
    </Switch >
  );
};

export default AppRouter;

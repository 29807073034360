const defaultState = [];

function statusReducer(state = defaultState, action) {
    switch (action.type) {
        case 'ADD_STATUS':
            console.log("ADD_STATUS");
            return [
                ...state,
                action.status
            ];
        case 'SET_STATUSES':
            console.log("SET_STATUSES", action.statuses);
            return [
                ...action.statuses
            ];
        case 'UPDATE_STATUS':
            console.log("UPDATE_STATUS");
            const index = state.findIndex(status => status.id === action.status.id);
            if (index > -1) {
                state[index] = action.status;
            }
            return [
                ...state,

            ]
        case 'DELETE_STATUSES':
            console.log("DELETE_STATUSES");
            return state.filter((el) => el.id !== action.id);
        case 'CLEAR_STATUSES':
            console.log("CLEAR_STATUSES");
            return [
                ...defaultState,
            ];
        default:
            return state;
    }
}

export default statusReducer;

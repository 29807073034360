import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import {
  HomeFilled,
  ArrowLeftOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import logo from "./logo.svg";
import "./Sidebar.scss";
import { userLogout } from "../../actions/UserActions";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Sidebar(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState("user");
  const user = useSelector(state => state.userReducer)
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [nav, setNav] = useState(JSON.parse(localStorage.getItem("navigation") || "[]"))
  // console.log(location.pathname);
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);

    setOpenKeys(keys);
    // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };

  useEffect(() => {
    const host = window.location.hostname;
    if (host.includes("production.vakio")) {
      setOpenKeys(["portal_production"]);
    }
    if (host.includes("projects.vakio")) {
      setOpenKeys(["portal_projects"]);
    }
    if (host.includes("dealers.vakio")) {
      setOpenKeys(["portal_dealers"]);
    }
    if (window.location.pathname.includes("portal-services")) {
      setOpenKeys(["portal_service"]);
    }

  }, [])

  const checkAccess = (roles) => {

    const userRoles = user.capabilities.split(",")
    let access = false;
    roles.forEach(role => {
      if (userRoles.includes(role)) {
        access = true;
      }
    });
    return access;
  }
  useEffect(() => {
    fetch("https://portal.vakio.ru/api/routes", {
      method: "GET",

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }).then((response) => response.json()).then(response => {
      console.log("RESPONSE ", response);
      localStorage.setItem("navigation", JSON.stringify(response));
      setNav(response);
      setLoading(false);
    })
  }, [])
  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  useEffect(() => {
    setDefaultSelected(location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <Layout.Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth={window.screen.width < 450 ? 0 : undefined}
      collapsible={window.innerWidth > 450 ? true : false}
      width={window.innerWidth > 1400 || window.innerWidth < 450 ? 300 : 200}
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        // position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <img src={logo} alt="logo" className="sidebar__heading" />
      <Menu
        theme="dark"
        defaultSelectedKeys={["user"]}
        selectedKeys={[defaultSelected]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        className="sidebar__menu"
        items={[


          ...(!loading ? nav.filter(menu => checkAccess(menu.roles)).length > 1 ?
            nav.filter(menu => checkAccess(menu.roles)).map(item => {
              return {
                label: item.title,
                key: item.value,

                //icon: (<BarsOutlined className="sidebar__menu-icon" />),
                className: "sidebar__menu-item sidebar__text",
                children: item.submenus.filter(submenu => checkAccess(submenu.roles)).map(submenu => {
                  return {
                    key: submenu.route,
                    label: submenu.title,
                    onClick: () => {
                      //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                      if (!submenu.url.includes(window.location.hostname)) {
                        window.open(submenu.url + submenu.route, submenu.blanc ? "_blank" : "_self")
                        //history.push("/" + submenu.route)
                      } else {
                        history.push("/" + submenu.route)
                      }
                      //window.open("http://localhost:3000/" + submenu.route, "_self")
                    }
                  }
                })
                // onClick: () => {
                //   history.push("/dealers");
                //   if (window.screen.width < 450) {
                //     props.setCollapsed(true);
                //   }
                // }
              }
            })
            :
            nav.filter(menu => checkAccess(menu.roles))[0].submenus.map(item => {
              return {
                label: item.title,
                key: item.route,
                className: "sidebar__menu-item sidebar__text",
                onClick: () => {
                  //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                  if (!item.url.includes(window.location.hostname)) {
                    window.open(item.url + item.route, item.blanc ? "_blank" : "_self")
                  } else {
                    history.push("/" + item.route)
                  }
                }
                // onClick: () => {
                //   history.push("/dealers");
                //   if (window.screen.width < 450) {
                //     props.setCollapsed(true);
                //   }
                // }
              }
            })
            :
            []
          ),
          {
            label: "Выход",
            key: "logout",
            icon: <ArrowLeftOutlined className="sidebar__menu-icon" />,
            className: "sidebar__menu-item sidebar__text",
            onClick: () => {
              dispatch(userLogout());
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("projectUser");
              localStorage.removeItem("user");
              cookies.remove("accessToken");
              cookies.remove("refreshToken");
              cookies.remove("user");
              cookies.remove("accessToken", { domain: '.vakio.ru' });
              cookies.remove("refreshToken", { domain: '.vakio.ru' });
              cookies.remove("user", { domain: '.vakio.ru' });
            }
          }
        ]
        }
      />
    </Layout.Sider >
  );
}

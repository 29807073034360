
export const setHeaderTitle = (payload) => {
    return { type: "SET_HEADER_TITLE", payload }
}
export const setHeaderButtonData = (payload) => {
    return { type: "SET_HEADER_BUTTON_DATA", payload }
}
export const hideHeaderSubtitle = (payload) => {
    return { type: "HIDE_HEADER_SUBTITLE", payload }
}
export const setHeaderSubtitle = (payload) => {
    return { type: "SET_HEADER_SUBTITLE", payload }
}
export const setProjectId = (payload) => {
    console.log("SET PROJECT ID ", payload);
    return { type: "SET_PROJECT_ID", payload }
}

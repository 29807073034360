
import { requestApi, requestApiAuth } from ".";
export const client_id = "95fccb78-346a-433d-ad3d-597a38554111";
export const client_secret = "nrObsW7Ou7ibIQYeILwoT5NbcR0e9KJw6yEgdtRK";
export const grant_type = "password";

export default class UserService {
    static async login(username, password) {
        try {
            const response = await requestApiAuth("oauth/token",
                {
                    body: JSON.stringify({
                        username, password, client_id, client_secret, grant_type

                    }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }

    }
    static async getUserInfo() {
        try {
            const response = await requestApiAuth("api/user/get",
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
    static async updateUser(id, user) {
        try {
            const response = await requestApi("api/user/" + id,
                {
                    body: JSON.stringify({ ...user }),
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
    static async deleteUser(id) {
        try {
            const response = await requestApi("api/user/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
    static async createUser(user) {
        try {
            const response = await requestApiAuth("api/user",
                {
                    body: JSON.stringify({ ...user }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
}

export const api_url = "https://projects.vakio.ru/";
export const api_url_auth = "https://auth.vakio.ru/";
export const client_id = "95fccb78-346a-433d-ad3d-597a38554111";
export const client_secret = "nrObsW7Ou7ibIQYeILwoT5NbcR0e9KJw6yEgdtRK";
export const grant_type = "password";
// export const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NWZjY2I3OC0zNDZhLTQzM2QtYWQzZC01OTdhMzg1NTQxMTEiLCJqdGkiOiIxZDYxZWU0YTk4M2MyYmQzNTJkNjAxMjg1ZDQzN2Y4ZTlmNjcyMmY5MDhlYmQ5N2E1ZWQ3OTFmNWUwNDJiOTZkMDk5Mjg0ZGVlZTJhMWMwZCIsImlhdCI6MTY2MTE2MTI2OC41MjY0NTcsIm5iZiI6MTY2MTE2MTI2OC41MjY0NiwiZXhwIjoxNjYyNDU3MjY4LjUwMzMwNCwic3ViIjoiNjMiLCJzY29wZXMiOltdfQ.lRCS6f-6nb0aL9gUv0Tw-xPba1SEEHnUu3sfn6lMo_rtlEGjZcPhEsVslqMM5ux1b0MsH0hMM2WVBMhZ4Sd58qiYcbPBtZkknw7ankzBjO9vL8K8WyCfv4sKwiVxNA6jkW6QFxa7B46OY12hNlhjOCMzMv5YrxUZSEFzouT1Gd60zT6D6LBzAp7q9vsEUvhzzuzbyMAu5dACWQqkyWOswauiqIC49I-bBNEvxmEeBHmL1CrfKhDJzY2l4s3ym3XMlhd2plc-aDyRVmh3M0hhCL8xVU_6yE-kxtLp5Bwl_DHwgYw2hhN50LR0hVh8MdQ3bqa5Z6XZ8XWSbsPLpeui_gRJWgCOh2gBABNyYuy0TELlg8lk_usZZW07AUlq0ExppY2HB62ygEXe5s13QOy8YVqjXp9CKXu4w1D6l6Zwvso7P4JI_y65lV4RkMOh8sGnid-0xuJ2fOb9DSoZ8NiUjp6kYQBlH9skYXtcBWIimHk3FPS8VtU5I6cPs4_H55rKxSif4szhwftR0fWQCDUMbAF9-uwZdTSIDYplOgt1OsyzrmgDAg0CzsYq1Zi87GD30UgCsSPrdH42FBN-rT4sMztpXrGMPqh0oF4vA6V_fiXc5mpsPXZPoMv7L-A3lonU5sjjnfcKgfXrpNY-ksp1lBUC4rXsDHmJVoWGc_w6FuI"
export const requestApi = async (uri, parameters) => {
    const token = await localStorage.getItem("accessToken");
    parameters.headers.Authorization = "Bearer " + token
    const response = await fetch(api_url + uri,
        { ...parameters });
    if (!response.ok) {

        throw response
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {

        const json = await response.json();
        return { data: json, status: response.status };
    }

    return { status: response.status };

}
export const requestApiAuth = async (uri, parameters) => {
    const token = await localStorage.getItem("accessToken");
    parameters.headers.Authorization = "Bearer " + token
    const response = await fetch(api_url_auth + uri,
        { ...parameters });
    if (!response.ok) {
        throw response
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {

        const json = await response.json();
        return { data: json, status: response.status };
    }

    return { status: response.status };

}

import React, { useEffect, useState } from 'react';
import "./ProjectItem.scss"
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Select, Typography, Popconfirm, message } from "antd"
import { useDispatch, useSelector } from 'react-redux';
import { getNameById } from '../../utils/utils';
import UpdateProjectModal from './UpdateProjectModal';
import { useHistory } from 'react-router';
import { deleteProject, setProjectBrigadiers, setProjectCoordinators, setProjectSupervisors, setProjectUsers } from '../../actions/ProjectsActions';
import ProjectService from '../../services/ProjectsService';
import UsersService from '../../services/UsersService';
const { Title } = Typography;
const ProjectItem = ({ project }) => {
    const [visible, setVisible] = useState(false)
    const user = useSelector((state) => state.userReducer)
    const users = useSelector((state) => state.usersReducer)
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [projectUsers, setProjectUsers_] = useState([])
    const [projectCoordinators, setProjectCoordinators_] = useState([])
    const [projectBrigadiers, setProjectBrigadiers_] = useState([])
    const [projectSupervisors, setProjectSupervisors_] = useState([])
    const history = useHistory();
    const dispatch = useDispatch()
    useEffect(() => {
        setProjectUsers_([...project.project_users.map((user_) => { return { value: user_.id, label: getNameById(user_.user, users) } })]);
        setProjectSupervisors_([...project.project_supervisors.map((user_) => { return { value: user_.id, label: getNameById(user_.user, users) } })]);
        setProjectBrigadiers_([...project.project_brigadiers.map((user_) => { return { value: user_.id, label: getNameById(user_.user, users) } })]);
        setProjectCoordinators_([...project.project_coordinators.map((user_) => { return { value: user_.id, label: getNameById(user_.user, users) } })]);
    }, [project, users])
    const openUsers = () => {
        setVisible(!visible);
    }

    const showUpdateModalClick = (project) => {
        setShowUpdateModal(true);
    }

    const routeToProject = () => {
        history.push("/project/" + project.id)
    }

    const handleAddSupervisor = (value) => {

        const index = project.project_supervisors.findIndex(user => user.user === value);
        if (index === -1) {
            UsersService.createProjectUser({ user: value, project_id: project.id, role: "p_supervisor" }).then(response => {
                const newUser = users.p_supervisors.find(item => item.id === value);
                const newSelectUser = { value: response.data.id, label: newUser.name };
                setProjectSupervisors_([newSelectUser, ...projectUsers]);
                dispatch(setProjectSupervisors(project.id, [response.data, ...project.project_supervisors]))
                message.success(newUser.name + " был успешно добавлен")
            }).catch(error => console.log(error))
        } else {
            message.error("Пользователь уже добавлен!")
        }

    }


    const handleAddUser = (value) => {
        const index = project.project_users.findIndex(user => user.user === value);
        if (index === -1) {
            UsersService.createProjectUser({ user: value, project_id: project.id, role: "p_user" }).then(response => {
                const newUser = users.p_users.find(item => item.id === value);
                const newSelectUser = { value: response.data.id, label: newUser.name };
                setProjectUsers_([newSelectUser, ...projectUsers]);
                dispatch(setProjectUsers(project.id, [response.data, ...project.project_users]))
                message.success(newUser.name + " был успешно добавлен")
            }).catch(error => console.log(error))
        } else {
            message.error("Пользователь уже добавлен!")
        }
    }

    const handleAddBrigadier = (value) => {

        const index = project.project_brigadiers.findIndex(user => user.user === value);
        if (index === -1) {
            UsersService.createProjectUser({ user: value, project_id: project.id, role: "p_brigadier" }).then(response => {
                const newUser = users.p_brigadier.find(item => item.id === value);
                const newSelectUser = { value: response.data.id, label: newUser.name };
                setProjectBrigadiers_([newSelectUser, ...projectBrigadiers]);
                dispatch(setProjectBrigadiers(project.id, [response.data, ...project.project_brigadiers]))
                message.success(newUser.name + " был успешно добавлен")
            }).catch(error => console.log(error))
        } else {
            message.error("Пользователь уже добавлен!")
        }
    }

    const handleDeleteBrigadier = (value) => {
        const newSelectValue = projectBrigadiers.filter(user => user.value !== value);
        const newProjectUsers = newSelectValue.map(user_ => {
            return project.project_brigadiers.find(itm => user_.value === itm.id)
        });
        const userName = projectBrigadiers.find(item => item.value === value).label

        UsersService.deleteProjectUser(value).then(response => {
            console.log("delete user done!");
            setProjectBrigadiers_([...newSelectValue]);
            dispatch(setProjectBrigadiers(project.id, newProjectUsers));
            message.success(userName + " был успешно удалён")

        }).catch(error => console.log("Error add supervisor", error))
    }
    const handleAddCoordinator = (value) => {

        const index = project.project_coordinators.findIndex(user => user.user === value);
        if (index === -1) {
            UsersService.createProjectUser({ user: value, project_id: project.id, role: "p_coordinator" }).then(response => {
                const newUser = users.p_coordinator.find(item => item.id === value);
                const newSelectUser = { value: response.data.id, label: newUser.name };
                setProjectCoordinators_([newSelectUser, ...projectCoordinators]);
                dispatch(setProjectCoordinators(project.id, [response.data, ...project.project_coordinators]))
                message.success(newUser.name + " был успешно добавлен")
            }).catch(error => console.log(error))
        } else {
            message.error("Пользователь уже добавлен!")
        }
    }

    const handleDeleteCoordinator = (value) => {
        const newSelectValue = projectCoordinators.filter(user => user.value !== value);
        const newProjectUsers = newSelectValue.map(user_ => {
            return project.project_coordinators.find(itm => user_.value === itm.id)
        });
        const userName = projectCoordinators.find(item => item.value === value).label

        UsersService.deleteProjectUser(value).then(response => {
            console.log("delete user done!");
            setProjectCoordinators_([...newSelectValue]);
            dispatch(setProjectCoordinators(project.id, newProjectUsers));
            message.success(userName + " был успешно удалён")

        }).catch(error => console.log("Error add supervisor", error))
    }

    const handleDeleteUser = (value) => {
        const newSelectValue = projectUsers.filter(user => user.value !== value);
        const newProjectUsers = newSelectValue.map(user_ => {
            return project.project_users.find(itm => user_.value === itm.id)
        });
        const userName = projectUsers.find(item => item.value === value).label

        UsersService.deleteProjectUser(value).then(response => {
            console.log("delete user done!");
            setProjectUsers_([...newSelectValue]);
            dispatch(setProjectUsers(project.id, newProjectUsers));
            message.success(userName + " был успешно удалён")

        }).catch(error => console.log("Error add supervisor", error))
    }

    const handleDeleteSupervisor = (value) => {
        const newSelectValue = projectSupervisors.filter(user => user.value !== value);
        const newProjectSupervisors = newSelectValue.map(user_ => {
            return project.project_supervisors.find(itm => user_.value === itm.id)
        });
        const userName = projectSupervisors.find(item => item.value === value).label
        UsersService.deleteProjectUser(value).then(response => {
            console.log("delete supervisor done!");
            setProjectSupervisors_([...newSelectValue]);
            dispatch(setProjectSupervisors(project.id, newProjectSupervisors));
            message.success(userName + " был удалён из проекта")
        }).catch(error => console.log("Error add supervisor", error))
    }


    const deleteProjectConfirm = (project) => {
        ProjectService.deleteProjects(project.id).then(response => {
            if (response.status === 204) {
                message.success("Проект " + project.title + " успешно удален!");
                dispatch(deleteProject(project.id))
            }

        }).catch((error) => console.log(error));
    }

    const cancel = (e) => {

    };



    return (
        <div className='project' >
            <div className='project__title' style={{
                borderRaduis: visible ? 10 : null,
                borderEndStartRadius: visible ? 0 : 8,
                borderEndEndRadius: visible ? 0 : 8,
            }}
                onClick={routeToProject}>

                <Title

                    level={3}
                    style={{ marginBottom: 0, marginLeft: 10 }}

                > {project.title} </Title>

                <div onClick={e => e.stopPropagation()} style={{ padding: 10, alignSelf: "flex-end" }}>
                    <Button shape="circle" icon={visible ? <UpOutlined /> : <DownOutlined />} size={20} style={{ marginRight: 10 }} onClick={() => openUsers()} />
                    {user.isAdmin &&
                        <>
                            <Button shape="circle" icon={<EditOutlined />} style={{ marginRight: 10 }} size={20} onClick={() => showUpdateModalClick(project)} />
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title="Удалить проект?"
                                onConfirm={() => deleteProjectConfirm(project)}
                                onCancel={cancel}
                                okText="Удалить"
                                cancelText="Отмена"
                            >
                                <Button shape="circle" icon={<DeleteOutlined />} size={20} />
                            </Popconfirm>
                        </>}

                </div>

            </div>
            {visible ? <div className='project__body' onClick={e => e.stopPropagation()}>


                <div className='project__users'>

                    <Title type="secondary" level={5}>Руководитель проекта</Title>
                    <Select
                        mode="multiple"

                        style={{
                            width: '100%',
                            marginBottom: 10,
                        }}
                        disabled={!user.isAdmin}
                        placeholder="Выберите руководителей проекта"
                        value={[...projectSupervisors]}
                        onDeselect={handleDeleteSupervisor}
                        onSelect={handleAddSupervisor}
                        options={
                            users.p_supervisors.map(user => {

                                return { label: user.name, value: user.id }
                            })
                        }
                    >
                    </Select >

                    <Title type="secondary" level={5}>Координаторы</Title>
                    <Select
                        mode="multiple"

                        style={{
                            width: '100%',
                        }}
                        disabled={!user.isAdmin}
                        placeholder="Выберите координаторов"
                        value={[...projectCoordinators]}
                        onSelect={handleAddCoordinator}
                        onDeselect={handleDeleteCoordinator}
                        options={
                            users.p_coordinator.map(user => {
                                return { label: user.name, value: user.id }
                            })
                        }
                    >
                    </Select>

                    <Title type="secondary" level={5}>Бригадиры</Title>
                    <Select
                        mode="multiple"

                        style={{
                            width: '100%',
                        }}
                        disabled={!user.isAdmin}
                        placeholder="Выберите бригадиров"
                        value={[...projectBrigadiers]}
                        onSelect={handleAddBrigadier}
                        onDeselect={handleDeleteBrigadier}
                        options={
                            users.p_brigadier.map(user => {
                                return { label: user.name, value: user.id }
                            })
                        }
                    >
                    </Select>
                    <Title type="secondary" level={5}>Исполнители</Title>
                    <Select
                        mode="multiple"

                        style={{
                            width: '100%',
                        }}
                        disabled={!user.isAdmin}
                        placeholder="Выберите исполнителя"
                        value={[...projectUsers]}
                        onSelect={handleAddUser}
                        onDeselect={handleDeleteUser}
                        options={
                            users.p_users.map(user => {
                                return { label: user.name, value: user.id }
                            })
                        }
                    >
                    </Select>
                </div>


            </div> : <></>}
            <div onClick={e => e.stopPropagation()}>

                <UpdateProjectModal
                    create={false}

                    project={project}
                    setShowUpdateModal={setShowUpdateModal}
                    showUpdateModal={showUpdateModal} />
            </div>
        </div >
    );
};

export default ProjectItem;

import { requestApi } from ".";
export default class StatusService {
    static async getStatuses() {
        try {
            const response = await requestApi("api/status",
                {

                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }

}
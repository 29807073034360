
import { requestApi } from ".";
export default class FileService {
    static async uploadFile(id, base, folder) {
        try {
            const response = await requestApi("api/file",
                {
                    body: JSON.stringify({
                        "type": "project_task",
                        "type_id": id,
                        image: base,
                        folder: folder
                    }),
                    method: "POST",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }

    }
    static async deleteFile(id) {
        try {
            const response = await requestApi("api/file/" + id,
                {
                    method: "DELETE",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }

    }
    static async updateFile(id, file) {
        try {
            const response = await requestApi("api/file/" + id,
                {
                    body: JSON.stringify(file),
                    method: "PUT",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }

    }
}
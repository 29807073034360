export const userLogin = ({
    access_token,
    refresh_token }) => {
    return {
        type: "LOGIN",
        access_token,
        refresh_token
    };
};
export const userLogout = () => {

    return {
        type: "LOGOUT"
    };
};
export const setLogin = () => {

    return {
        type: "SET_LOGIN"
    };
};
export const setUserInfo = (user) => {
    return {
        type: "SET_USER",
        user
    };
};

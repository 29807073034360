import Cookies from 'universal-cookie';

const cookies = new Cookies();
const defaultState = {
    isLogin: false,
    username: null,
    user_id: null,
    access_token: null,
    s_isAdmin: false,
    isAdmin: false,
    d_isManager: false,
    d_isUser: false,
    p_isAdmin: false,
    p_isUser: false,
    p_isSupervisor: false,
    p_isBrigadier: false,
    p_isCoordinator: false,
    refresh_token: null,
    all: []
};

function userReducer(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN':
            console.log("LOGIN");

            cookies.set('accessToken', (action.access_token), { domain: '.vakio.ru' });
            cookies.set('refreshToken', (action.refresh_token), { domain: '.vakio.ru' });

            return {
                ...state,
                access_token: action.access_token,
                refresh_token: action.refresh_token
            };
        case 'SET_USER':
            console.log(action.user);
            localStorage.setItem("user", JSON.stringify(action.user));
            cookies.set('user', (action.user), { domain: '.vakio.ru' });
            return {
                ...state,
                ...action.user,
                s_isAdmin: action.user.capabilities.includes("s_admin") || false,
                isAdmin: action.user.capabilities.includes("super_admin") || false,
                d_isManager: action.user.capabilities.includes("d_manager") || false,
                d_isUser: action.user.capabilities.includes("d_user") || false,
                p_isAdmin: action.user.capabilities?.includes("p_admin") || false,
                p_isUser: action.user.capabilities?.includes("p_user") || false,
                p_isSupervisor: action.user.capabilities?.includes("p_supervisor") || false,
                p_isBrigadier: action.user.capabilities?.includes("p_brigadier") || false,
                p_isCoordinator: action.user.capabilities?.includes("p_coordinator") || false,
            };
        case 'SET_LOGIN':
            return {
                ...state,
                isLogin: true,
            };
        case 'LOGOUT':
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export default userReducer;

import { Button, Modal, Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProject, updateProjects } from '../../actions/ProjectsActions';
import ProjectService from '../../services/ProjectsService';

import { getNameById } from '../../utils/utils';

const UpdateProjectModal = ({ project, showUpdateModal, setShowUpdateModal, create }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const users = useSelector(state => state.usersReducer)
    const [projectSupervisors, setProjectSupervisors] = useState([])
    const [projectBriadiers, setProjectBrigadiers_] = useState([])
    const [projectsCoordinators, setProjectCoordinators_] = useState([])
    const [projectUsers, setProjectUsers_] = useState([])
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    useEffect(() => {
        if (showUpdateModal) {

            const selectedUsers = [...project?.project_users.map((user) => { return { value: user.user, label: getNameById(user.user, users) } })]
            const selectedSupervisors = [...project?.project_supervisors.map((user) => { return { value: user.user, label: getNameById(user.user, users) } })]
            const selectedBriadiers = [...project?.project_brigadiers.map((user) => { return { value: user.user, label: getNameById(user.user, users) } })]
            const selectedCoordinators = [...project?.project_coordinators.map((user) => { return { value: user.user, label: getNameById(user.user, users) } })]
            setProjectUsers_(selectedUsers);
            setProjectSupervisors(selectedSupervisors);
            setProjectBrigadiers_(selectedBriadiers);
            setProjectCoordinators_(selectedCoordinators);
            form.setFieldsValue({ name: project.title, project_users: selectedUsers, project_supervisors: selectedSupervisors, project_brigadiers: selectedBriadiers, project_coordinators: selectedCoordinators })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showUpdateModal]);
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                const supervisordIds = values.project_supervisors.map(item => item.value === undefined ? item : item.value);
                const usersIds = values.project_users.map(item => item.value === undefined ? item : item.value);
                const coordinatorIds = values.project_coordinators.map(item => item.value === undefined ? item : item.value);
                const bridaierIds = values.project_brigadiers.map(item => item.value === undefined ? item : item.value);
                if (create) {
                    ProjectService.createProjects({ project_coordinators: coordinatorIds, project_brigadiers: bridaierIds, project_users: usersIds, project_supervisors: supervisordIds, title: values.name }).then(response => {
                        console.log("craete user done!", response.data);
                        dispatch(addProject(response.data))
                        setShowUpdateModal(false);
                    }).catch(error => console.log("create add supervisor", error))
                } else {
                    ProjectService.updateProjects(project.id, { project_coordinators: coordinatorIds, project_brigadiers: bridaierIds, project_users: usersIds, project_supervisors: supervisordIds, title: values.name }).then(response => {
                        console.log("add user done!", response.data);
                        dispatch(updateProjects(response.data))
                        setShowUpdateModal(false);
                    }).catch(error => console.log("Error add supervisor", error))
                }


            }).catch(error => console.log("Error form", error));

    };

    const handleCancel = () => {
        setConfirmLoading(false);
        setShowUpdateModal(false);
    };

    return (
        <>

            <Modal
                onClick={e => e.stopPropagation()}
                title={project.title}
                forceRender
                visible={showUpdateModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Сохранить
                    </Button>]}
            >
                <Form layout="vertical" form={form} >
                    <Form.Item label="Имя проекта" name="name" rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}>
                        <Input placeholder="Имя проекта" />
                    </Form.Item>

                    <Form.Item label="Руководители проекта" name="project_supervisors" rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]} >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',

                            }}
                            placeholder="Выберите руководителей проекта"
                            value={[...projectSupervisors]}
                            options={

                                users.p_supervisors.map(user => {
                                    return { label: user.name, value: user.id }
                                })
                            }
                        >
                        </Select >
                    </Form.Item>
                    <Form.Item label="Координаторы" name="project_coordinators" rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Выберите координатора"
                            value={[...projectsCoordinators]}
                            options={
                                users.p_coordinator.map(user => {
                                    return { label: user.name, value: user.id }
                                })
                            }
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item label="Бригадиры" name="project_brigadiers" rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Выберите бригадира"
                            value={[...projectBriadiers]}
                            options={
                                users.p_brigadier.map(user => {
                                    return { label: user.name, value: user.id }
                                })
                            }
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item label="Исполнители" name="project_users" rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Выберите исполнителя"
                            value={[...projectUsers]}
                            options={
                                users.p_users.map(user => {
                                    return { label: user.name, value: user.id }
                                })
                            }
                        >
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default UpdateProjectModal;
const defaultState = {
    p_users: [],
    p_supervisors: [],
    p_admin: [],
    p_coordinator: [],
    p_brigadier: [],
};

function usersReducer(state = defaultState, action) {
    switch (action.type) {

        case 'SET_USERS':
            console.log("SET_USERS", action.users);
            return {
                ...action.users
            };
        case 'CLEAR_USERS':
            console.log("CLEAR_USERS");
            return {
                ...defaultState,
            };
        default:
            return state;
    }
}

export default usersReducer;

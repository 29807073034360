import React, { createContext } from "react";
import App from "./App";
import { Provider } from 'react-redux'
import store from './projects-src/store/store'
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';
import ruRU from "antd/lib/locale/ru_RU";
import { ConfigProvider } from "antd";

export const Context = createContext(null);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <ConfigProvider locale={ruRU}>
    <Provider store={store}>
      <App />

    </Provider>
  </ConfigProvider >,
);

reportWebVitals();
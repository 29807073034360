import React, { useState, useEffect } from "react";
import { Layout, Button, Typography } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "./Header.scss";
import { useSelector } from "react-redux";

import CardPreview from "./CartPreview"
import UpdateProjectModal from "../../pages/MainPage/UpdateProjectModal";
import UpdateTaskModal from "../../pages/ProjectPage/UpdateTaskModal";

export default function Header(props) {
  const layout = useSelector((state) => state.layoutReducer);
  const user = useSelector((state) => state.userReducer);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateTaskModal, setShowUpdateTaskModal] = useState(false);
  const location = useLocation()
  const [buttonText, setButtonText] = useState("")
  console.log(user.capabilities);
  useEffect(() => {
    if (location.pathname.includes("projects")) {
      setButtonText("Новый проект")
    }
    if (location.pathname.includes("project/")) {
      setButtonText("Новая задача")
    }
  }, [location.pathname])
  return (
    <Layout.Header className="site-layout-background header">
      <Button
        className="header__menu-button"
        shape="default"
        type="text"
        icon={<MenuOutlined />}
        onClick={() => {
          props.setState(!props.state);
        }}
      />
      <div className="header__title-container">
        <Typography.Title
          level={(layout?.showHeaderSubtitle || window.screen.width) < 450 ? 4 : 2}
          ellipsis={true}
          style={{ maxWidth: "100%" }}
          className="header__title"
        >
          {layout.headerTitle}
        </Typography.Title>
        {layout?.showHeaderSubtitle && (
          <Typography.Text className="header__subtitle">{layout?.headerSubtitle}</Typography.Text>
        )}
      </div>
      <CardPreview />
      <div className="header__actions">
        {((user?.isAdmin && location.pathname.includes("projects")) || ((user?.isAdmin || user?.isSupervisor) && !location.pathname.includes("projects")) && !location.pathname.includes("project_settings")) && <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{
            marginLeft: "auto",
            marginRight: 16,
          }}
          onClick={() => {

            if (location.pathname.includes("projects")) {
              setShowUpdateModal(true);
            } else {
              console.log("THERE");
              setShowUpdateTaskModal(true);
            }
          }}
        >
          {window.screen.width > 450 ? buttonText : ""}
        </Button>}
        {(user?.isAdmin || user.isSupervisor) && <UpdateTaskModal
          create={true}
          task={{
            "status_id": 1,
            "project_id": Number(layout.project_id),
            "plan_date": "",
            "name": "",
            "phone": "",
            "address": "",
            "comment": "",
            "rejection_comment": "",
            "device_qty": 1,
            "files": [],
            "project_task_users": []
          }}
          setUpdateModalShow={setShowUpdateTaskModal}
          updateModalShow={showUpdateTaskModal}
        />}
        {user?.isAdmin && <UpdateProjectModal
          create={true}
          project={{ title: "", project_users: [], project_supervisors: [], project_brigadiers: [], project_coordinators: [] }}
          setShowUpdateModal={setShowUpdateModal}
          showUpdateModal={showUpdateModal}
        />}

      </div>
    </Layout.Header>
  );
}

import React, { useState } from 'react';
import { Divider, Card, Upload, Popconfirm, message, Button, Form, Input, Modal } from "antd"
import "./TaskItem.scss"
import { BarsOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getNameById } from '../../utils/utils';
import styled from 'styled-components'
import TaskService from '../../services/TaskService';
import { setTasks, updateTasks, addTaskFile } from '../../actions/TasksActions';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import FileService from '../../services/FileService';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
const StyledUpload = styled(Upload)`
    .ant-upload-list-item {
      margin-top: 0px;
      flex-wrap: wrap;
    }
    .ant-upload-list{
        flex-wrap: wrap;
    }
    .ant-upload-list-item-card-actions-btn{
        opacity:1
    }
  `;

const getStatusColor = (id) => {
    switch (id) {
        case 1:
            return "#E4E9E5"
        case 2:
            return "#d1eaff"
        case 3:
            return "#95deaa44"
        case 4:
            return "#95ef6444"
        case 5:
            return "#ff787560"
        default:
            return "#d1eaff";
    }
}
const TaskItem = ({ task, onClick }) => {
    const status = useSelector(state => state.statusReducer);
    const users = useSelector(state => state.usersReducer)
    const user = useSelector(state => state.userReducer)
    const tasks = useSelector(state => state.tasksReducer)
    const location = useLocation();
    const [mobile, setMobile] = useState(window.screen.width > 450 ? false : true);
    const [oneProject, setOneProject] = useState(location.pathname.includes("project_task"))
    const [fileList, setFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [rejectComment, setRejectComment] = useState('');
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState(false);
    const [fileq, setFileQ] = useState(null)
    const [modalStatus, setModalStatus] = useState(0)
    const [photoList, setPhotoList] = useState([]);

    const dispatch = useDispatch();
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const beforeUpload = (file, folder) => {

        if (folder === "file") {
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("Вы можете загрузить файл только в форматах JPG/PNG!");
            }
            setFileList([...fileList, {
                uid: "123123123",
                name: "",
                url: "",
                status: 'uploading',
                folder: folder
            }])
            return isJpgOrPng && 1;
        }
        else {
            setPhotoList([...fileList, {
                uid: "123123123",
                name: "",
                url: "",
                status: 'uploading',
                folder: folder
            }])
        }
        //const isLt2M = file.size / 1024 / 1024;
        // if (!isLt2M) {
        //     message.error("Изображенте должно быть менее 2MB!");
        // }
        return 1;
    };


    const setFiles = () => {
        const newFilesFormat = task?.files?.filter(file => file.folder === "file").map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setFileList(newFilesFormat);
    }
    const setPhotos = () => {
        const newFilesFormat = task?.files?.filter(file => file.folder === "photo").map(file => {
            return {
                uid: file.id,
                name: "",
                url: file.thumb_link,
                status: 'done'
            }

        }) || []
        setPhotoList(newFilesFormat);
    }

    const updateStatus = (status, rejection_comment) => {
        setError(false);
        if (status === 5) {
            if (rejection_comment === "") {
                setError(true);
                return;
            } else {
                task.rejection_comment = rejection_comment;
            }
        }
        task.status_id = status;
        TaskService.updateTask(task.id, task).then(response => {
            const newTask = response.data;
            setVisible(false);
            dispatch(updateTasks(newTask));
        }).catch(error => {
            message.error("Не удалось изменить статус!")
            console.log("Error update task", error)
        });

    }

    const deleteTaskConfirm = (task) => {
        TaskService.deleteTask(task.id).then(response => {
            if (response.status === 204) {
                message.success("Задача " + task.name + " успешно удалена!");
                dispatch(setTasks([...tasks.filter((task_) => task_.id !== task.id)]))
            }

        }).catch((error) => console.log(error));
    }

    const removeFile = () => {

        FileService.deleteFile(fileq.uid).then((response) => {
            if (response.status === 204) {
                const del_index = tasks.findIndex(task_ => task_.id === task.id);
                const newState = JSON.parse(JSON.stringify(tasks));
                newState[del_index].files = newState[del_index].files.filter(file => file.id !== fileq.uid);
                task.files = newState[del_index].files;
                dispatch(setTasks(newState))

                setFiles();


            }
            console.log(response);
        }).catch(error => console.log("DELETE FILE", error));
    }
    function onImageChange(fileq, folder) {
        if (fileq.file.status === 'removed') {

        } else {
            const file = fileq.file.originFileObj;

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function () {
                // console.log(reader.result);
                FileService.uploadFile(task.id, reader.result, folder).then(response => {
                    console.log("RESPONSE ", response);



                    if (response.data.folder === "file") {
                        setFileList([...fileList, response.data])
                    } else {
                        console.log("SET TMP FILES");
                        setPhotos([...photoList, response.data])
                    }
                    dispatch(addTaskFile(task.id, response.data))

                }).catch(error => {
                    setFileList([...fileList.filter(file => file.status !== "uploading")])
                    message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
                });
            };

            reader.onerror = function () {
                console.log(reader.error);
                setFileList([...fileList.filter(file => file.status !== "uploading")])

                message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
            };
        }
    }
    return (
        <>
            {oneProject &&
                <div style={{ width: "100%", display: "flex" }}>
                    <Button type="primary" style={{ marginTop: 10, marginBottom: 10, marginLeft: mobile ? 10 : 0 }} onClick={onClick}>
                        Редактировать
                    </Button>
                </div>}

            <Card
                style={{ marginBottom: 20, borderRadius: 8 }}
                bodyStyle={{ backgroundColor: task.backlight, borderRadius: 8 }}
                className="task__card"
                onClick={oneProject ? () => { } : onClick}
            >

                <div className='task' >
                    {user.isAdmin &&

                        <div className="delete_task_button" onClick={e => { e.stopPropagation() }}>
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title="Удалить задачу?"
                                onConfirm={() => deleteTaskConfirm(task)}
                                onCancel={() => { }}
                                okText="Удалить"
                                cancelText="Отмена"
                                placement="topRight"
                            >
                                <Button

                                    shape="text"
                                    style={{ borderEndStartRadius: 20, color: "#ff6060" }}
                                    icon={<DeleteOutlined style={{ fontSize: '20px' }} />} />
                            </Popconfirm>
                        </div>
                    }
                    <div className="task__left">
                        <div className="task__orgs">
                            <div className="task__orgs-block">
                                <div className="task__org-title">Имя</div>
                                <div className="task__our-org-name">{task.name}</div>
                            </div>
                            <div onClick={e => e.stopPropagation()}>
                                <div className="task__org-title">Адрес</div>
                                <div className="task__org-desc">
                                    {task.address && (
                                        <Link target="_blank" to={{ pathname: `https://2gis.ru/novosibirsk/search/${task.address}` }}>{task.address} кв. {task.flat}</Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="task__left">
                        <div className="task__orgs">
                            <div onClick={e => e.stopPropagation()} className="task__orgs-block">
                                <div className="task__org-title">Телефон</div>
                                <a href={"tel:" + task.phone} className="task__our-org-name">{task.phone}</a>
                            </div>
                            <div>
                                <div className="task__org-title">Количество приборов</div>
                                <div className="task__org-name">
                                    {task.device_qty}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="task__center">
                        <div className="task__orgs">
                            <div className="task__org-title">Информация</div>
                            <div className="task__org-desc">{task.comment ?? 'отсутствует'}</div>
                            <div className="task__org-files">
                                <div className='task__files' onClick={e => e.stopPropagation()}>
                                    {!oneProject && task.files.filter(file => file.folder === "file").length > 0 && < div className="task__org-title" > Файлы: </div>}
                                    <StyledUpload
                                        multiple
                                        showUploadList={{
                                            showDownloadIcon: oneProject,
                                            showRemoveIcon: oneProject,
                                            removeIcon: (
                                                <Popconfirm
                                                    title={"Удалить файл " + fileq?.name}
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}

                                                    onConfirm={() => removeFile()}
                                                    onCancel={() => { }}
                                                    okText="Удалить"
                                                    cancelText="Отмена"
                                                    placement="topRight"
                                                >

                                                    <DeleteOutlined />
                                                </Popconfirm>
                                            )
                                        }}
                                        openFileDialogOnClick
                                        onChange={(fileq) => onImageChange(fileq, "file")}
                                        beforeUpload={(fileq) => beforeUpload(fileq, "file")}
                                        style={{ display: 'flex' }}
                                        onPreview={handlePreview}
                                        onRemove={(fileq) => { setFileQ(fileq); }}
                                        fileList={task.files.filter(file => file.folder === "file").map(file => {

                                            return {
                                                uid: file.id,
                                                name: file.id,
                                                thumbUrl: file.thumb_link,
                                                url: file.thumb_link,
                                                status: 'done'
                                            }

                                        }) || []}>
                                        {(task.files.length >= 10 || !oneProject) ? null : <div>

                                            <div style={{ fontSize: 15, color: "#1890ff" }}>Файлы +</div>
                                        </div>}

                                    </StyledUpload>
                                </div>
                                <div className='task__files' onClick={e => e.stopPropagation()}>
                                    {!oneProject && task.files.filter(file => file.folder === "photo").length > 0 && <div className="task__org-title">Фотоотчет: </div>}
                                    <StyledUpload
                                        multiple
                                        showUploadList={{
                                            showDownloadIcon: oneProject,
                                            showRemoveIcon: oneProject,
                                            removeIcon: (
                                                <Popconfirm
                                                    title={"Удалить файл " + fileq?.name}
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}

                                                    onConfirm={() => removeFile()}
                                                    onCancel={() => { }}
                                                    okText="Удалить"
                                                    cancelText="Отмена"
                                                    placement="topRight"
                                                >

                                                    <DeleteOutlined />
                                                </Popconfirm>
                                            )
                                        }}
                                        openFileDialogOnClick
                                        onChange={(fileq) => onImageChange(fileq, "photo")}
                                        beforeUpload={(fileq) => beforeUpload(fileq, "photo")}
                                        style={{ display: 'flex' }}
                                        onPreview={handlePreview}
                                        onRemove={(fileq) => { setFileQ(fileq); }}
                                        fileList={task.files.filter(file => file.folder === "photo").map(file => {

                                            return {
                                                uid: file.id,
                                                name: file.id,
                                                thumbUrl: file.thumb_link,
                                                url: file.thumb_link,
                                                status: 'done'
                                            }

                                        }) || []}>
                                        {(task.files.length >= 10 || !oneProject) ? null : <div>

                                            <div style={{ fontSize: 15, color: "#1890ff" }}>Фотоотчет +</div>
                                        </div>}

                                    </StyledUpload>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="task__center">
                <div className="task__orgs">
                    <div className="task__org-title">Комментарий</div>
                    <div className="task__org-name">
                        {task.rejection_comment}
                    </div>
                </div>
            </div> */}
                    <div className="task__right" >
                        {window.screen.width > 450 ?
                            <>
                                <div className="task__amount">
                                    {task.plan_date !== null ? moment(task.plan_date).format("DD.MM.YYYY") : "Не спланирована"}
                                </div>
                                <div className="task__amount">
                                    {task.plan_date !== null ? moment(task.plan_date).format("HH:mm") : undefined}
                                </div>
                            </> :
                            <div className="task__amount">
                                {task.plan_date !== null ? moment(task.plan_date).format("DD.MM.YYYY HH:mm") : "Не спланирована"}
                            </div>}

                        {window.screen.width > 450 && <Divider style={{ marginBottom: 10, marginTop: 10, borderColor: task.backlight === null ? "white" : task.backlight }} />}


                        <div onClick={e => e.stopPropagation()}>

                            <div className="task__stage" onClick={e => {
                                if ((task.status_id === 3 && (user.isAdmin || user.isUser || user.isBrigadier))) {
                                    setError(false);
                                    setRejectComment("")
                                    setModalStatus(task.status_id)
                                    setVisible(true);
                                }
                                e.stopPropagation()
                            }}>

                                <div className="task__status" style={{ marginRight: 10, background: getStatusColor(task.status_id) }}>
                                    {status.length > 0 && status.find(item => item.id === task.status_id)?.title}


                                </div>
                                {(task.status_id === 3 && (user.isAdmin || user.isUser || user.isBrigadier)) && <Button type="primary" shape="circle" icon={<BarsOutlined />} size={"small"} />}
                            </div>
                        </div>
                        <Divider style={{ marginBottom: 10, marginTop: 10, borderColor: task.backlight === null ? "white" : task.backlight }} />
                        <div className="task__debt">

                        </div>
                    </div>
                </div >
                <div style={{ display: "flex", paddingLeft: 10 }}>


                    {(user.isAdmin || user.isBrigadier || user.isSupervisor) && <div className='task__users'>


                        <div>Исполнители: </div>

                        {task.project_task_users.length > 0 && task.project_task_users.map((user, index, arr) => {
                            if (index < arr.length - 1)
                                return <div key={user.user} style={{ marginLeft: 5 }}>{getNameById(user.user, users)}, </div>
                            else
                                return <div key={user.user} style={{ marginLeft: 5 }}>{getNameById(user.user, users)}</div>
                        })}
                        {task.project_task_users.length === 0 && (<div key={user.user} style={{ marginLeft: 5 }}>не назначены </div>)}
                    </div>
                    }
                    <div onClick={e => e.stopPropagation()}>

                        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                            <img
                                alt="Изображение"
                                style={{
                                    width: '100%',
                                }}
                                src={previewImage}
                            />
                        </Modal>

                        <Modal
                            onClick={e => e.stopPropagation()}
                            // title={project.title}
                            visible={visible}
                            style={{ marginTop: 100 }}
                            onCancel={() => setVisible(false)}
                            footer={modalStatus === 2 ?
                                [<Button type="primary" onClick={() => updateStatus(3, "")} style={{ marginLeft: 10 }}>Изменить</Button>] :
                                [<Button type="primary" danger onClick={() => updateStatus(5, rejectComment)}>Отменен</Button>,
                                <Button type="primary" onClick={() => updateStatus(4, "")} style={{ marginLeft: 10 }}>Успешно</Button>]}
                        >
                            {visible && <>{modalStatus === 1 ?
                                <div >
                                    <div style={{ fontSize: 16, fontWeight: 400, marginBottom: 10 }}>Изменить статус на "В работе" ?</div>
                                </div>
                                :
                                <div >
                                    <div style={{ fontSize: 16, fontWeight: 400, marginBottom: 10 }}>С каким результатом завершить задачу?</div>
                                    <div>
                                        <Form.Item style={{ marginBottom: 0 }} validateStatus={error ? "error" : undefined} help={error ? "Заполните комментарий  исполнителя!" : undefined}>
                                            <Input.TextArea rows={2}
                                                value={rejectComment}
                                                onChange={(e) => setRejectComment(e.target.value)}
                                                placeholder="Комментарий исполнителя"
                                            // value={isLoginAvailable ? username : ""}
                                            />
                                        </Form.Item>
                                    </div>

                                </div>}
                            </>}

                        </Modal>
                    </div>
                </div>
            </Card >
        </>
    );
};

export default TaskItem;
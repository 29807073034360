
import { requestApi, requestApiAuth } from ".";
export default class UsersService {
    static async getUsers() {
        try {
            const response = await requestApi("api/user",
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }

    }
    static async deleteProjectUser(id) {
        try {
            const response = await requestApi("api/user_project_link/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
    static async createProjectUser(user) {
        try {
            const response = await requestApi("api/user_project_link",
                {
                    body: JSON.stringify(user),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw (error);
        }
    }
}
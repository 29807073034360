import React, { useState, useEffect } from "react";
import { Card, Typography, Table, Modal, Button, Input, message, Select, Form } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import "../../styles/Card.scss";
import { useDispatch } from "react-redux";
import { setHeaderTitle } from "../../actions/LayoutActions";
import UserService from "../../services/UserService";
import { setUsers } from "../../actions/UsersActions";
import { userLogout } from "../../actions/UserActions";
import UsersService from "../../services/UsersService";
import "./Settings.scss"
export default function SettingsPage() {
  const dispatch = useDispatch();
  const [reloadPage, setReloadPage] = useState(true);
  const [editUser, setEditUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [addModalVis, setAddModalVis] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [tableUsers, setTableUsers] = useState([]);
  const [phoneError, setPhoneError] = useState(true);
  const [updatedIndex, setUpdatedIndex] = useState(0);

  const [form] = Form.useForm();

  const DynamicPhone = (props) => {
    const cellphoneMask = "(00) 0 0000-0000";
    const phoneMask = "+7 (000) 000-00-00";

    // always memoize dynamic masks
    const mask = React.useMemo(
      () => [
        {
          mask: cellphoneMask,
          lazy: false,
        },
        {
          mask: phoneMask,
          lazy: false,
        },
      ],
      []
    );

    return (
      <MaskedInput
        {...props}
        mask={mask}
        maskOptions={{
          dispatch: function (appended, dynamicMasked) {
            const isCellPhone = dynamicMasked.unmaskedValue[2] === "9";
            return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
          },
        }}
      />
    );
  };

  useEffect(() => {
    // if (Object.keys(editUser).length === 0) return;
    if (editUser === undefined) return;
    if (editUser.email === "") {
      form.setFieldsValue(editUser);
      setCreateNew(true);
    } else {
      // if (editUser_.capabilities != "")
      //   editUser_.capabilities = editUser_.capabilities.split(", ").map(cap => { return { value: unroles[cap], label: cap } })

      form.setFieldsValue(editUser);
      setCreateNew(false);
    }
    setAddModalVis(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  useEffect(() => {
    dispatch(setHeaderTitle("Настройки"));
    setPhoneError(false);
    const getAllUsers = async () => {
      setIsLoading(true);
      const response = await UsersService.getUsers().catch(error => {
        if (error.status === 403) {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          dispatch(userLogout());

        }

      });
      if (response.status === 200) {
        dispatch(setUsers(response.data));
        const users = response.data;
        const p_admin = users.p_admin.map(admin => { return { ...admin, capabilities: "p_admin" } })
        const p_supervisors = users.p_supervisors.map(admin => { return { ...admin, capabilities: "p_supervisor" } })
        const p_users = users.p_users.map(admin => { return { ...admin, capabilities: "p_user" } })
        const p_coordinator = users.p_coordinator.map(admin => { return { ...admin, capabilities: "p_coordinator" } })
        const p_brigadier = users.p_brigadier.map(admin => { return { ...admin, capabilities: "p_brigadier" } })
        const tableUsers_ = [...p_admin, ...p_supervisors, ...p_brigadier, ...p_coordinator, ...p_users];
        const filteredUsers = tableUsers_.reduce((result, obj) => {
          if (result.findIndex(user => user.id === obj.id) === -1) {
            obj.capabilities = [obj.capabilities]
            obj.view = roles[obj.capabilities]
            result.push(obj);
          } else {
            result.map(user => {
              if (user.id === obj.id) {
                user.capabilities.push(obj.capabilities);
                user.view += ", " + roles[obj.capabilities]

                console.log(user.capabilities);
              }
              return user
            })
          }
          return result
        }, []);
        setIsLoading(false);
        setTableUsers(filteredUsers);
      } else {

      }
      setIsLoading(false);
    }
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPage]);

  function generatePassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    form.setFieldsValue({
      password: retVal,
    });
  }

  function sendForm() {
    form
      .validateFields()
      .then((values) => {

        const copy = Object.assign({}, values);

        copy.phone = "+" + copy.phone
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .split(" ")
          .join("");
        if (copy.phone === "+7") {
          setPhoneError(true);
          return;
        }
        if (createNew) {

          UserService.createUser(copy).then((resposne) => {
            if (resposne.status === 500) {
              throw "error"
            }
            setAddModalVis(false);
            form.resetFields();
            message.success("Пользователь добавлен");
            setReloadPage(!reloadPage);
            setUpdatedIndex(editUser.id)
            setTimeout(() => {
              setUpdatedIndex(0)
            }, 1000)

          }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
        } else {
          UserService.updateUser(editUser.id, copy).then((resposne) => {
            setAddModalVis(false);
            form.resetFields();
            message.success("Пользователь обновлен");
            setReloadPage(!reloadPage);
            setUpdatedIndex(editUser.id)
            setTimeout(() => {
              setUpdatedIndex(0)
            }, 1000)
          }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
        }


      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  const roles = {
    "p_user": "Исполнитель",
    "p_admin": "Администратор",
    "p_supervisor": "Руководитель проекта",
    "p_coordinator": "Координатор",
    "p_brigadier": "Бригадир",
  }
  const unroles = {
    "Исполнитель": "p_user",
    "Администратор": "p_admin",
    "Руководитель проекта": "p_supervisor",
    "Координатор": "p_coorditor",
    "Бригадир": "Бригадир",
  }

  const deleteUser = (id) => {
    UserService.deleteUser(id).then(response => {
      message.error("Пользователь успешно удалён")
    }).catch(error => message.error("Не удалось удалить пользователя"))
  }
  return (
    <div>
      <Card
        title={<Typography.Title level={3}>Аккаунты</Typography.Title>}
        style={{ marginBottom: 20 }}
        extra={
          <Button
            type="default"
            // shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditUser({
                capabilities: "p_user",
                email: "",
                name: "",
                phone: "",
                title: "",
              });
            }}
          >
            {/* {window.screen.width > 450 && "Добавить пользователя"} */}
            Добавить
          </Button>
        }
        bordered={false}
        className="card"
      >
        <Table
          pagination={false}
          // pagination={{
          //   defaultCurrent: 1,
          //   total: 500,
          //   pageSize: 500,
          // }}
          rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
          bordered={true}
          loading={isLoading}
          columns={[
            {
              title: "Название аккаунта",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Имя",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "E-mail",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Роль",
              dataIndex: "view",
              key: "view",
              render: (text) => (<div>{text}</div>)
            },

            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (_, full) => (
                <Typography.Link
                  href="#"
                  onClick={(e) => {
                    setAddModalVis(true)
                    e.preventDefault();
                    setEditUser(full);
                  }}
                >
                  <EditOutlined />
                </Typography.Link>
              ),
            },
            {
              title: "",
              dataIndex: "delete",
              key: "delete",
              render: (_, full) => (
                <Typography.Link
                  href="#"
                  onClick={(e) => {

                    e.preventDefault();
                    deleteUser(full.id)
                  }}
                >
                  <DeleteOutlined />
                </Typography.Link>
              ),
            },
          ]}
          dataSource={tableUsers ? tableUsers : []}
          scroll={{ x: 300 }}
          rowKey="id"
        />
      </Card>


      <Modal
        title={
          <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} пользователя</Typography.Title>
        }
        centered
        visible={addModalVis}
        onOk={sendForm}
        okText={createNew ? "Добавить" : "Обновить"}
        onCancel={() => setAddModalVis(false)}
        closable={false}
        destroyOnClose
        forceRender
        width={window.screen.width < 450 ? "100%" : 450}
        // zIndex={10000}
        bodyStyle={{ paddingBottom: 16 }}
      >
        <Form
          name="wrap"
          // labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
          initialValues={editUser}
          layout="vertical"
        >
          <Form.Item
            label="Название аккаунта"
            required
            name="title"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите название аккаунта" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="Имя пользователя"
            required
            name="name"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите имя" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="E-mail"
            required
            name="email"
            rules={[
              {
                required: true,
                message: "Некорректный E-mail",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Введите e-mail" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="Телефон"
            required
            name="phone"
            validateStatus={phoneError ? "error" : undefined}
            help={phoneError ? "Заполните номер телефона!" : undefined}
            rules={[
              {
                required: false,
                message: "Некорректный телефон",
                type: "string",
              },
            ]}
          >
            {/* <Input placeholder="Введите телефон" className="settings__input" /> */}
            <DynamicPhone />
          </Form.Item>

          <Form.Item
            label="Пароль"
            required
            name="password"
            rules={[
              {
                required: false,
                message: "Минимальная длина пароля: 8 символов",
              },
            ]}
          >
            <Input
              placeholder="Введите пароль"
              suffix={
                <Button type="default" size="small" onClick={generatePassword}>
                  Сгенерировать
                </Button>
              }
              className="settings__input"
            />
          </Form.Item>

          <Form.Item label="Роль" required name="capabilities">
            <Select className="settings__input" mode="multiple" onChange={() => console.log(1)}>
              <Select.Option value="p_admin">Администратор</Select.Option>
              <Select.Option value="p_supervisor">Руководитель проекта</Select.Option>
              <Select.Option value="p_coordinator">Координатор</Select.Option>
              <Select.Option value="p_brigadier">Бригадир</Select.Option>
              <Select.Option value="p_user">Исполнитель</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

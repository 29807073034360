
import ProjectService from "../../services/ProjectsService.js";
import { setProjects } from "../../actions/ProjectsActions.js";
import { setUsers } from "../../actions/UsersActions.js";
import UsersService from "../../services/UsersService.js";
import { setLogin, setUserInfo, userLogout } from "../../actions/UserActions.js";
import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";


import MainPage from "../../pages/MainPage/MainPage.js";

import ProjectPage from "../../pages/ProjectPage/ProjectPage.js";

import { useDispatch, useSelector } from "react-redux";

import SettingsPage from "../../pages/SettingsPage/SettingsPage";
import UserService from "../../services/UserService.js";

const ProjectRoutes = () => {
    const user = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(window.screen.width > 450 ? false : true);
    const location = useLocation();
    const history = useHistory();



    useEffect(() => {
        if (user.isLogin) {
            const getProjects = () => {
                ProjectService.getProjects().then(response => {
                    if (response?.status === 200) {
                        dispatch(setProjects(response.data));
                    }
                }).catch(error => {
                    if (error?.status === 403) {

                        dispatch(userLogout());

                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("user");
                        localStorage.removeItem("userToken");
                        history.push("/")
                    }

                })


            }
            const getAllUsers = async () => {
                const response = await UsersService.getUsers().catch(error => {
                    if (error.status === 403) {

                        localStorage.removeItem("accessToken");
                    }

                });
                if (response.status === 200) {
                    dispatch(setUsers(response.data))
                } else {

                }
            }
            if (!location.pathname.includes("project_settings"))
                getAllUsers();
            getProjects();
        }
        // eslint-disable-next-line
    }, [user.isLogin])
    useEffect(() => {
        const userToken = localStorage.getItem("userToken");
        if (userToken) {
            localStorage.setItem("accessToken", userToken);
            UserService.getUserInfo().then(response => {
                const newUser = response.data.user
                dispatch(setLogin())
                dispatch(setUserInfo({ ...newUser, userToken, refresh_token: "" }))
                localStorage.setItem("user_project", JSON.stringify(newUser));

            }).catch(error => {
                console.log(error);
            })
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Route path={"/projects"} component={MainPage} />
            <Route path={"/"} component={MainPage} exact />
            <Route path={"/project/:id"} component={ProjectPage} />
            <Route path={"/project_task/:id"} component={ProjectPage} />
            <Route path={"/project_settings"} component={SettingsPage} />
            {/* <Route path="/settings" component={SettingsPage} /> */}

        </>
    );
};

export default ProjectRoutes;
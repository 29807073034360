import { createStore, combineReducers } from 'redux';

import userReducer from "./UserReducer"
import projectsReducer from "./ProjectsReducer"
import usersReducer from "./UsersReducer"
import tasksReducer from "./TasksReducer"
import statusReducer from "./StatusReducer"
import layoutReducer from "./LayoutReducer"


const allReducers = combineReducers({
  projectsReducer,
  userReducer,
  usersReducer,
  tasksReducer,
  statusReducer,
  layoutReducer
});

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;

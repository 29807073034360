const defaultState = [];

function tasksReducer(state = defaultState, action) {
    switch (action.type) {
        case 'ADD_TASK':
            console.log("ADD_TASK");
            return [
                action.task,
                ...state

            ];
        case 'SET_TASKS':
            console.log("SET_TASKS", action.tasks);
            return [
                ...action.tasks
            ];
        case 'UPDATE_TASK':
            console.log("UPDATE_TASK");
            const index = state.findIndex(task => task.id === action.task.id);
            if (index > -1) {
                state[index] = action.task;
            }
            return [
                ...state,

            ]
        case 'DELETE_FILE':
            console.log("DELETE_FILE");



            console.log(state);
            return [
                ...state
            ]
        case 'ADD_FILE':
            console.log("ADD_FILE", action.file);
            const add_index = state.findIndex(task => task.id === action.id);

            if (add_index > -1) {

                state[add_index].files.push(action.file);
            }
            return [
                ...state,

            ]
        case 'DELETE_TASKS':
            console.log("DELETE_TASKS");
            return state.filter((el) => el.id !== action.id);
        case 'CLEAR_TASKS':
            console.log("CLEAR_TASKS");
            return [
                ...defaultState,
            ];
        default:
            return state;
    }
}

export default tasksReducer;


import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./projects-src/components/AppRouter/AppRouter";
import 'antd/dist/antd.min.css'
import { useDispatch, useSelector } from "react-redux";
import UserService from "./projects-src/services/UserService";
import { setLogin, setUserInfo, userLogin, userLogout } from "./projects-src/actions/UserActions";
import { Spin } from "../node_modules/antd/lib/index";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
console.log = () => { };
function App() {

  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!user.isLogin) {
      const access_token = cookies.get("accessToken");
      const refresh_token = cookies.get("refreshToken");
      const user_ = cookies.get("user");

      if (access_token && user_) {
        dispatch(setUserInfo(user_));
        dispatch(userLogin({ access_token, refresh_token }));
        dispatch(setLogin());
        UserService.getUserInfo().then(response => {

          dispatch(setUserInfo(response.data.user));

        }).catch(error => {
          dispatch(userLogout())
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
        }).finally(() => {
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><Spin size="large" /></div>;
  return (
    // <div className="App" style={{ overflow: "hidden" }}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
    // </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { setTasks } from '../../actions/TasksActions';
import TaskService from '../../services/TaskService';
import TaskItem from './TaskItem';
import "./ProjectPage.scss"
import StatusService from '../../services/StatusService';
import { setStatuses } from '../../actions/StatusActions';
import { setHeaderTitle, setProjectId } from '../../actions/LayoutActions';
import {
    Button,
    Card,
    DatePicker,
    Divider,
    Empty,
    Input,
    Modal,
    Pagination,
    Popconfirm,
    Radio,
    Spin,
    Table,
    Typography,
    Upload
} from "antd";
import { EditOutlined, TableOutlined, DeleteOutlined, DatabaseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import UpdateTaskModal from './UpdateTaskModal';
import { getNameById } from '../../utils/utils';
import moment from 'moment';
import 'moment/locale/ru'  // without this line it didn't work
import styled from 'styled-components';

moment.locale('ru')
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
const StyledUpload = styled(Upload)`
    .ant-upload-list-item {
      margin-top: 0px;
      flex-wrap: wrap;
    }
    .ant-upload-list{
        flex-wrap: wrap;
    }
    .ant-upload-list-item-card-actions-btn{
        opacity:1
    }
  `;
const StyledPagination = styled(Pagination)`
@media screen and (max-width: 450px) {
    .ant-pagination-options { 
        display:inline-block;
    }
  }
  `;
const ProjectPage = () => {
    const { id } = useParams();
    const [updateModalShow, setUpdateModalShow] = useState(false);
    const tasks = useSelector(state => state.tasksReducer)
    const users = useSelector(state => state.usersReducer)
    const user = useSelector(state => state.userReducer)
    const [isMobile, setIsMobile] = useState(window.screen.width < 450)
    const projects = useSelector(state => state.projectsReducer)
    const location = useLocation();
    const [oneProject, setOneProject] = useState(location.pathname.includes("project_task"))
    const [targetTask, setTargetTask] = useState({})
    const [projectUsers, setProjectUsers] = useState([])
    const [radioFilter, setRadioFilter] = useState(Number(localStorage.getItem("radioFilter")) || 0);
    const [initRadioFilter, setInitRadioFilter] = useState(Number(localStorage.getItem("radioFilter")) || 0);
    const [radioFilterOption, setRadioFilterOption] = useState([])
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [flat, setFlat] = useState("")
    const [userSelect, setUser] = useState("")
    const [currentPage, setCurrentPage] = useState(Number(localStorage.getItem("currentPage")) || 1)
    const [count, setCount] = useState(0)
    const [pageSize, setPageSize] = useState(Number(localStorage.getItem("pageSize")) || 10)
    const [tableTasks, setTableTasks] = useState([])
    const [date, setDate] = useState("")
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [updateTableStask, setUpdateTableTask] = useState(true)
    const status = useSelector(state => state.statusReducer);
    const [viewMode, setViewMode] = useState(1)
    const [fileq, setFileQ] = useState(null)


    useEffect(() => {
        const target = projects.find(project => project.id === Number(id))
        if (target) {
            const selectedUsers = target.project_users.map((user) => {

                return { value: user.user, label: getNameById(user.user, users) }
            })
            console.log(selectedUsers);
            setProjectUsers([...selectedUsers]);
        }

    }, [projects])
    useEffect(() => {
        const tableTasks_ = tasks.map(task => {
            task.file = task.files.filter((file) => file.folder === 'file');
            task.photo = task.files.filter((file) => file.folder === 'photo');
            return task;
        })
        console.log("tableTasks_", tableTasks_);
        setTableTasks(tableTasks_);
    }, [updateTableStask, updateModalShow, tasks.length])
    const resetLocalFilters = () => {
        localStorage.removeItem("phone");
        localStorage.removeItem("date");
        localStorage.removeItem("address");
        localStorage.removeItem("radioFilter");
        localStorage.removeItem("flat");
        setFlat("")
        setDate("");
        setPhone("")
        setAddress("")
    }
    const getFilterOptions = () => {
        let filters = [];

        localStorage.setItem("radioFilter", radioFilter);
        localStorage.setItem("date", date);
        localStorage.setItem("phone", phone);
        localStorage.setItem("address", address);
        localStorage.setItem("flat", flat);
        if (radioFilter !== 0 && radioFilter !== -1) {
            filters.push({

                field: "status_id",
                comparison: "=",
                value: Number(radioFilter),

            })
        }
        if (radioFilter === -1) {
            filters.push({

                field: "plan_date",
                comparison: "=",
                value: null,

            })
        }
        if (address !== "") {

            filters.push({

                field: "address",
                comparison: "like",
                value: address,

            })
        }
        if (flat !== "") {

            filters.push({

                field: "flat",
                comparison: "like",
                value: flat,

            })
        }
        if (phone !== "") {

            filters.push({

                field: "phone",
                comparison: "like",
                value: phone,

            })
        }
        if (date !== "") {

            filters.push({

                field: "plan_date",
                comparison: "like",
                value: moment(date).format("YYYY-MM-DD") == 'Invalid date' ? "" : moment(date).format("YYYY-MM-DD"),

            })
        }
        if (userSelect !== "" && userSelect !== null) {
            filters.push({

                field: "user",
                comparison: "=",
                value: userSelect,

            })
        }
        if (location.pathname.includes("project_task")) {
            return [];
        }
        return filters;
    }

    useEffect(() => {

        dispatch(setHeaderTitle("Задачи"));
        dispatch(setProjectId(id));

        StatusService.getStatuses().then((response) => {
            if (response.status === 200) {

                dispatch(setStatuses(response.data))


            }
        }).catch(error => console.log(error));

        const viewMode_ = Number(localStorage.getItem("viewMode")) || 0;
        setViewMode(viewMode_);
        const localPhone = localStorage.getItem("phone") || "";
        const localDate = moment(localStorage.getItem("date")) || "";
        const localAddress = localStorage.getItem("address") || "";
        const localFlat = localStorage.getItem("flat") || "";
        setFlat(localFlat)
        setAddress(localAddress);
        setDate(localDate.toString() !== "Invalid date" ? localDate : "");
        setPhone(localPhone);

    }, []);


    useEffect(() => {
        if (user.isAdmin || user.isSupervisor || user.isCoordinator)
            setRadioFilterOption([{ label: "Все", value: 0 }, ...status.map((status) => { return { label: status.title, value: status.id } })]);
        else
            setRadioFilterOption([{ label: "Все", value: 0 }, ...status.filter(status => status.id > 2).map((status) => { return { label: status.title, value: status.id } })]);
    }, [status.length])
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(true);

            const filters = getFilterOptions();
            console.log(filters);
            if (location.pathname.includes("project_task")) {
                TaskService.getProjectTasks(id, filters).then(response => {
                    if (response.status === 200) {
                        dispatch(setTasks(response.data.project_tasks))

                    }
                }).then(error => {
                    console.log(error)
                }).finally(() => setLoading(false));
            } else {
                TaskService.getProjectTasksSearch(id, filters, pageSize, currentPage).then(response => {
                    if (response.status === 200) {
                        dispatch(setTasks(response.data.project_tasks))
                        setUpdateTableTask(!updateTableStask)
                        console.log(response.data.count);
                        setCount(response.data.count)
                    }
                }).then(error => {
                    console.log(error)
                }).finally(() => setLoading(false));
            }
        }, 650);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioFilter, date, address, phone, userSelect, currentPage, pageSize, flat])

    const openUpdateTaskModal = (task) => {
        setTargetTask(task);
        setUpdateModalShow(true);
    }
    const onChange = (page) => {
        console.log(page);

        setCurrentPage(page)
        localStorage.setItem("currentPage", page);
        // setAllChecked(false);
        // setCheckedRow(() => []);
    };
    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        localStorage.setItem("pageSize", pageSize);
        setCurrentPage(currentPage);
        localStorage.setItem("currentPage", currentPage);
        // operationStore.setCurrentPage(operationStore.currentPage);

        // setAllChecked(false);
        // setCheckedRow(() => []);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const setTaskViewMode = (newMode) => {

        localStorage.setItem("viewMode", newMode);
        setViewMode(newMode);

    }
    return (
        <div className='task-list' style={isMobile ? { display: "flex", flexDirection: "column", alignItems: "center" } : {}}>
            <UpdateTaskModal setUpdateModalShow={setUpdateModalShow} updateModalShow={updateModalShow} task={targetTask} create={false} />
            {!oneProject && <Card style={isMobile ? { display: 'flex', flexWrap: "wrap", justifyContent: "center", width: "95%", marginBottom: 20 } : { marginBottom: 20 }} bordered={false} className="card">
                <div className="filter-field filter-field--no-margin">

                    <div className="filter-field__segmented">
                        <Radio.Group
                            size="large"
                            options={radioFilterOption}
                            onChange={(e) => {
                                setCurrentPage(1);
                                localStorage.setItem("currentPage", 1);

                                setRadioFilter(e.target.value)
                            }}
                            optionType="button"
                            defaultValue={initRadioFilter}
                            buttonStyle="solid"
                        />
                    </div>
                    <div className="filter-field__item">
                        <Input
                            placeholder="Поиск по адресу"
                            size="large"
                            onChange={e => {
                                setCurrentPage(1);
                                localStorage.setItem("currentPage", 1);
                                setAddress(e.target.value)
                            }}
                            style={{ marginTop: 5 }}
                            value={address}
                        />
                    </div>
                    <div className="filter-field__item">
                        <Input
                            placeholder="Поиск по квартире"
                            size="large"
                            onChange={e => {
                                setCurrentPage(1);
                                localStorage.setItem("currentPage", 1);
                                setFlat(e.target.value)
                            }}
                            style={{ marginTop: 5 }}
                            value={flat}

                        />
                    </div>
                    <div className="filter-field__item">
                        <Input
                            placeholder="Поиск по телефону"
                            size="large"
                            onChange={e => {
                                setCurrentPage(1);
                                localStorage.setItem("currentPage", 1);
                                setPhone(e.target.value)
                            }}
                            style={{ marginTop: 5 }}
                            value={phone}

                        />
                    </div>


                    {/* <div className="filter-field__item">
                        <Select
                        size="large"
                        allowClear
                        style={{
                                width: '100%',
                                marginTop: 10
                            }}
                            onChange={e => setUser(e)}
                            placeholder="Поиск по исполнителю"
                            // value={[...taskUsers]}
                            options={
                                projectUsers
                                
                            }
                            />
                            
                        </div> */}
                    <div className="filter-field__item">
                        <DatePicker
                            size="large"
                            allowClear={true}
                            value={date}
                            onChange={e => {
                                setCurrentPage(1);
                                localStorage.setItem("currentPage", 1);
                                setDate(e)
                            }}
                            style={{ marginTop: 10, width: "100%" }}
                            format="DD-MM-YYYY" placeholder="Дата"
                        />
                    </div>

                    <div className="filter-field__item">
                        <Button style={{ marginTop: 10 }} size='large' type="primary" onClick={resetLocalFilters}>
                            Сбросить
                        </Button>
                    </div>
                    <div style={{ width: "100%", marginTop: 10, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>

                        {!isMobile && <div style={{ marginRight: 20 }}>
                            <Radio.Group value={viewMode} buttonStyle="solid">
                                <Radio.Button value={0} onChange={() => setTaskViewMode(0)}><DatabaseOutlined /></Radio.Button>
                                <Radio.Button value={1} onChange={() => setTaskViewMode(1)}><TableOutlined /></Radio.Button>
                            </Radio.Group>
                        </div>}

                        <StyledPagination

                            current={currentPage}
                            total={10}
                            onChange={onChange}
                            defaulPageSize={10}
                            pageSize={pageSize}
                            onShowSizeChange={onShowSizeChange}
                            showSizeChanger
                        />

                    </div>
                </div>
            </Card>}


            {
                oneProject || viewMode === 0 || isMobile ?
                    !loading ? (tasks.length > 0 ? tasks.map((task, index, allArr) => {
                        let newGroup = false;
                        let noGroup = false;
                        if (index === 0) {
                            newGroup = true;
                        } else {
                            const prevDate = moment(allArr[index - 1].plan_date).format("YYYY-MM-DD")
                            const newDate = moment(task.plan_date).format("YYYY-MM-DD")
                            if (prevDate !== newDate) newGroup = true;
                        }

                        return <div key={task.id} style={isMobile ? { display: 'flex', width: "100%", flexWrap: "wrap", justifyContent: "center" } : {}} >
                            {noGroup && <Divider>Не спланированные</Divider>}
                            {newGroup && <Divider>{task.plan_date !== null ? moment(task.plan_date).format("LL") : "Не спланированные"}</Divider>}
                            <TaskItem task={task} onClick={() => openUpdateTaskModal(task)} />
                        </div>
                    })
                        :
                        <Empty description={<span>Задач не найдено</span>} />)
                        :
                        <div style={{ display: "flex", width: "100%", marginTop: 100, justifyContent: "center" }}>
                            <Spin size="large" />
                        </div>

                    :
                    <Table
                        pagination={false}
                        rowClassName={(record, index) => `color${record.backlight?.replace("#", "")}`}
                        bordered={true}
                        loading={loading}
                        columns={[
                            {
                                title: "Дата",
                                dataIndex: "plan_date",
                                key: "plan_date",
                                render: (text) => (<div>{text === null ? "Не спланирована" : moment(text).format("YYYY-MM-DD HH:mm")}</div>)
                            },
                            {
                                title: "Имя",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "Адрес",
                                dataIndex: "address",
                                key: "address",
                            },
                            {
                                title: "Квартира",
                                dataIndex: "flat",
                                key: "flat",
                                width: 20
                            },
                            {
                                title: "Телефон",
                                dataIndex: "phone",
                                key: "phone",
                            },
                            {
                                title: "Количество приборов",
                                dataIndex: "device_qty",
                                key: "device_qty",
                                width: 20,
                                render: (text) => (<div>{text}</div>)
                            },
                            {
                                title: "Информация",
                                dataIndex: "comment",
                                key: "comment",
                                render: (text) => (<div>{text}</div>)
                            },
                            {
                                title: "Файлы",
                                dataIndex: "file",
                                key: "file",
                                render: (files) => (<StyledUpload
                                    multiple
                                    showUploadList={{
                                        showDownloadIcon: oneProject,
                                        showRemoveIcon: oneProject,
                                        removeIcon: (
                                            <Popconfirm
                                                title={"Удалить файл " + fileq?.name}
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}

                                                // onConfirm={() => removeFile()}
                                                onCancel={() => { }}
                                                okText="Удалить"
                                                cancelText="Отмена"
                                                placement="topRight"
                                            >

                                                <DeleteOutlined />
                                            </Popconfirm>
                                        )
                                    }}
                                    openFileDialogOnClick
                                    // onChange={(fileq) => onImageChange(fileq, "photo")}
                                    // beforeUpload={(fileq) => beforeUpload(fileq, "photo")}
                                    style={{ display: 'flex' }}
                                    onPreview={handlePreview}
                                    onRemove={(fileq) => { setFileQ(fileq); }}
                                    fileList={files.filter(file => file.folder === "file").map(file => {

                                        return {
                                            uid: file.id,
                                            name: file.id,
                                            thumbUrl: file.thumb_link,
                                            url: file.thumb_link,
                                            status: 'done'
                                        }

                                    }) || []}>
                                    {(files.length >= 10 || !oneProject) ? null : <div>

                                        <div style={{ fontSize: 15, color: "#1890ff" }}>Фотоотчет +</div>
                                    </div>}

                                </StyledUpload>)
                            },
                            {
                                title: "Фотоотчет",
                                dataIndex: "photo",
                                key: "photo",
                                render: (files) => (<StyledUpload
                                    multiple
                                    showUploadList={{
                                        showDownloadIcon: oneProject,
                                        showRemoveIcon: oneProject,
                                        removeIcon: (
                                            <Popconfirm
                                                title={"Удалить файл " + fileq?.name}
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}

                                                // onConfirm={() => removeFile()}
                                                onCancel={() => { }}
                                                okText="Удалить"
                                                cancelText="Отмена"
                                                placement="topRight"
                                            >

                                                <DeleteOutlined size={"large"} />
                                            </Popconfirm>
                                        )
                                    }}
                                    openFileDialogOnClick
                                    // onChange={(fileq) => onImageChange(fileq, "photo")}
                                    // beforeUpload={(fileq) => beforeUpload(fileq, "photo")}
                                    style={{ display: 'flex' }}
                                    onPreview={handlePreview}
                                    onRemove={(fileq) => { setFileQ(fileq); }}
                                    fileList={files.filter(file => file.folder === "photo").map(file => {

                                        return {
                                            uid: file.id,
                                            name: file.id,
                                            thumbUrl: file.thumb_link,
                                            url: file.thumb_link,
                                            status: 'done'
                                        }

                                    }) || []}>
                                    {(files.length >= 10 || !oneProject) ? null : <div>

                                        <div style={{ fontSize: 15, color: "#1890ff" }}>Фотоотчет +</div>
                                    </div>}

                                </StyledUpload>)
                            },

                            {
                                title: "Статус",
                                dataIndex: "status_id",
                                key: "status_id",
                                width: 150,
                                render: (text) => (<div>{status?.find(item => item.id === text)?.title}</div>)
                            },
                            {
                                title: "",
                                dataIndex: "update",
                                key: "update",
                                render: (_, full) => (
                                    <Typography.Link
                                        href="#"
                                        onClick={(e) => {

                                            e.preventDefault();
                                            openUpdateTaskModal(full);
                                        }}
                                    >
                                        <EditOutlined />
                                    </Typography.Link>
                                ),
                            },

                        ]}
                        dataSource={tableTasks ? tableTasks : []}
                        scroll={{ x: 300 }}
                        rowKey="id"
                    />

            }
            <div onClick={e => e.stopPropagation()}>

                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                    <img
                        alt="Изображение"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>

            </div>
        </div >

    );
};

export default ProjectPage;
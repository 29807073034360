export const addTask = (task) => {
    return { type: "ADD_TASK", task }
}
export const deleteTasks = (id) => {
    return { type: "DELETE_TASKS", id }
}
export const setTasks = (tasks) => {
    return { type: "SET_TASKS", tasks }
}

export const updateTasks = (task) => {
    return { type: "UPDATE_TASK", task }
}
export const removeTaskFile = (id, file_id) => {
    return { type: "DELETE_FILE", id, file_id }
}
export const addTaskFile = (id, file) => {
    return { type: "ADD_FILE", id, file }
}

export const clearTasks = () => {
    return { type: "CLEAR_TASKS" }
}
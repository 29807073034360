const defaultState = {
    headerTitle: "",
    showHeaderSubtitle: false,
    headerSubtitle: "",
    project_id: 0,
    headerButtonData: {
        action: "",
        data: {},
    },
};

function layoutReducer(state = defaultState, action) {
    switch (action.type) {
        case 'SET_HEADER_TITLE':
            console.log("SET_HEADER_TITLE");
            return {
                ...state,
                headerTitle: action.payload
            }
        case 'SET_HEADER_SUBTITLE':
            console.log("SET_HEADER_SUBTITLE");
            return {
                ...state,
                showHeaderSubtitle: true,
                headerSubtitle: action.payload
            }
        case 'HIDE_HEADER_SUBTITLE':
            console.log("HIDE_HEADER_SUBTITLE");
            return {
                ...state,
                showHeaderSubtitle: false,

            }
        case 'SET_PROJECT_ID':
            console.log("SET_PROJECT_ID");
            return {
                ...state,
                project_id: action.payload,

            }
        case 'SET_HEADER_BUTTON_DATA':
            console.log("SET_HEADER_BUTTON_DATA");
            return {
                ...state,
                headerButtonData: action.payload
            }
        default:
            return state;
    }
}

export default layoutReducer;

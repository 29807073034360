import React, { useEffect, useState } from 'react';
import "./MainPage.scss"
import { List, Space, Button } from "antd"
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ProjectItem from './ProjectItem';
import UpdateProjectModal from './UpdateProjectModal';
import { setHeaderTitle, setProjectId } from '../../actions/LayoutActions';
import { useHistory } from 'react-router';
import { clearTasks } from '../../actions/TasksActions';
import UserService from '../../services/UserService';
import { setLogin, setUserInfo } from '../../actions/UserActions';
const MainPage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const projects = useSelector((state) => state.projectsReducer)
    const user = useSelector((state) => state.userReducer)
    const [loaded, setLoaded] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    useEffect(() => {

        dispatch(setHeaderTitle("Проекты"));

        dispatch(clearTasks())
        setLoaded(true);
        const userToken = localStorage.getItem("userToken");
        if (userToken) {
            localStorage.setItem("accessToken", userToken);
            UserService.getUserInfo().then(response => {
                const newUser = response.data.user
                dispatch(setLogin())
                dispatch(setUserInfo({ ...newUser, userToken, refresh_token: "" }))
                localStorage.setItem("user_project", JSON.stringify(newUser));

            }).catch(error => {
                console.log(error);
            })
        }
        // eslint-disable-next-line
    }, [])



    return (
        <div>
            {user.isAdmin && <Space wrap>
                <Button
                    type="text"
                    icon={<PlusOutlined />}
                    className="create-button"
                    size="large"
                    onClick={() => { console.log("THERE"); setShowUpdateModal(true); }}
                >
                    Новый проект
                </Button>
                <UpdateProjectModal
                    create={true}
                    project={{ title: "", project_users: [], project_supervisors: [], project_brigadiers: [], project_coordinators: [] }}
                    setShowUpdateModal={setShowUpdateModal}
                    showUpdateModal={showUpdateModal}
                />
            </Space>}
            {loaded &&
                <List
                    itemLayout="horizontal"
                    dataSource={projects}
                    renderItem={item => (
                        <List.Item className="list_item" style={{ justifyContent: window.screen.width > 450 ? undefined : "center" }}>
                            <ProjectItem project={item} />
                        </List.Item>
                    )}
                />
            }

        </div>
    );
};

export default MainPage;
export const addStatus = (status) => {
    return { type: "ADD_STATUS", status }
}
export const deleteStatuses = (id) => {
    return { type: "DELETE_STATUSES", id }
}
export const setStatuses = (statuses) => {
    return { type: "SET_STATUSES", statuses }
}
export const updateStatuses = (status) => {
    return { type: "UPDATE_STATUS", status }
}

export const clearStatuses = () => {
    return { type: "CLEAR_STATUSES" }
}
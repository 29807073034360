import React, { useEffect, useState } from 'react';
import logo from "./logo.svg";
import "./Login.scss"
import UserService from '../../services/UserService';
import { Form, Input, Button, Checkbox, Image, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLogin, setUserInfo } from '../../actions/UserActions';
const LoginPage = () => {
    const formRef = React.createRef();
    const [error, setError] = useState(false);


    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(atob(localStorage.getItem("round")), atob(localStorage.getItem("square")));
        const username = localStorage.getItem("username");
        const password = localStorage.getItem("password");


        if (username !== null && password !== null) {
            // setIsLoginAvailable(true);
            // setUsername(username);
            formRef.current.setFieldsValue({
                username, password
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = (values) => {
        setError(false);
        const username = values.username;
        const password = values.password;
        UserService.login(username, password).then(response => {

            if (values.remember) {
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
            } else {
                localStorage.removeItem("username");
                localStorage.removeItem("password");
            }
            const access_token = response.data.access_token;
            const refresh_token = response.data.refresh_token;

            localStorage.setItem("accessToken", access_token);
            localStorage.setItem("refreshToken", refresh_token);
            UserService.getUserInfo().then(response => {
                const newUser = response.data.user
                dispatch(setLogin())
                dispatch(setUserInfo({ ...newUser, access_token, refresh_token }))
                localStorage.setItem("user_project", JSON.stringify(newUser));
                history.push("/projects")
            }).catch(error => {
                setError(true);
            })
        }).catch((error) => {
            setError(true);
            console.log("error", error);
        });
        //UserService.login(...values);
    }

    return (
        <div className="signin">
            <Form
                name="normal_username"
                className="username-form signin__form"
                onFinish={login}
                initialValues={{ remember: true }}
                ref={formRef}
            >
                <div className="signin__image-container">
                    <div className="signin__image-frame">
                        <Image preview={false} src={logo} alt="logo" />
                    </div>
                    <div className="signin__image-description">PROJECTS</div>
                </div>
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                    Вход
                </Typography.Title>

                <Form.Item
                    name="username"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="E-mail"
                    // value={isLoginAvailable ? username : ""}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Пароль"
                    // value={isPassAvailable ? password : ""}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Запомнить меня</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="username-form-button">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default (LoginPage);
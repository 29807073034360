export const addProject = (project) => {
    return { type: "ADD_PROJECT", project }
}
export const deleteProject = (id) => {
    return { type: "DELETE_PROJECT", id }
}
export const deleteProjects = (id) => {
    return { type: "DELETE_PROJECTS", id }
}
export const setProjects = (projects) => {
    return { type: "SET_PROJECTS", projects }
}
export const setProjectUsers = (project_id, project_users) => {
    return { type: "SET_PROJECT_USERS", project_id, project_users }
}
export const setProjectSupervisors = (project_id, project_users) => {
    return { type: "SET_PROJECT_SUPERVISORS", project_id, project_users }
}
export const setProjectCoordinators = (project_id, project_users) => {
    return { type: "SET_PROJECT_COORDINATORS", project_id, project_users }
}
export const setProjectBrigadiers = (project_id, project_users) => {
    return { type: "SET_PROJECT_BRIADIERS", project_id, project_users }
}
export const updateProjects = (project) => {
    return { type: "UPDATE_PROJECT", project }
}

export const clearProjects = () => {
    return { type: "CLEAR_PROJECTS" }
}

import { requestApi } from ".";
export default class ProjectService {
    static async getProjects() {
        try {
            const response = await requestApi("api/project",
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {

            throw error
        }
    }
    static async updateProjects(id, project) {
        try {
            const response = await requestApi("api/project/" + id,
                {
                    body: JSON.stringify({ ...project }),
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async getProject(id) {
        try {
            const response = await requestApi("api/project/" + id,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async deleteProjects(id) {
        try {
            const response = await requestApi("api/project/" + id,
                {

                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
    static async createProjects(project) {
        try {
            const response = await requestApi("api/project",
                {
                    body: JSON.stringify({ ...project }),
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                });
            return response
        } catch (error) {
            throw error
        }
    }
}